
/*
    API calls for filling in the dropdown values on the mains screen
    - routes
        - directions
            - stops
    - locations/terminals
*/

import axios from "axios";
import { BaseURL, createFormData, getCurrentStore } from "./BaseRequestValues";

import RailRequests from "./RailRequests";

import axiosRetry from "axios-retry";

// retry the requests is they initially fail
axiosRetry(axios, {
	retries: 0, // number of retries
	/*    
	retryDelay: (retryCount) => {
		console.log(`retry attempt: ${retryCount}`);
		return retryCount * 2000; // time interval between retries
	},
	*/
	retryCondition: (error) => {
        console.log("GET CURRENT VUE?", getCurrentStore());
        console.log("TIMEOUT ERROR", error, axios);
		// if retry condition is not specified, by default idempotent requests are retried
		// return error.response.status === 503;
        const r = Math.random();
		return (r > 0.2) && false;
	}
});

//
//
export function getCurrentServerTime() {
    // console.log("GET SERVER TIME");

    const url = `${BaseURL}/api/busDV/getServerTime`;

    var config = {
        method: 'post',
        url
    };

    return axios(config);
}


//
//
export function getLocations(params) {

    console.log("GET LOCATIONS");

    if(params && params.mode === "RAIL") {
        console.log("SWITCH FOR RAIL STATIONS");
        return RailRequests.getRailStations();
    }

    const data = createFormData({
		mode: (params && params.mode) ? params.mode : "ALL"
	});

    const url = `${BaseURL}/api/busDV/getLocations`;

    var config = {
        method: 'post',
        url,
        data,
        timeout: 2000
    };

    return axios(config).then(({data}) => {
        // getCurrentStore().commit("setConsecutiveFailureCount", 0);
        return {data};
    });
}


//
//
export function getAllRoutes(params) {

    const data = createFormData({
		mode: (params && params.mode) ? params.mode : "ALL"
	});

    const url = `${BaseURL}/api/busDV/getBusRoutes`;

    var config = {
        method: 'post',
        url,
        data,
        timeout: 2000,
    };

    return axios(config);
}

//
//
export function getStopsForRoute(params){

    const data = createFormData({
        route: params.route,
        direction: params.direction
    });

    const url = `${BaseURL}/api/busDV/getStops`;

    var config = {
        method: 'post',
        url,
        data,
        timeout: 2000
    };

    return axios(config);
}

//
//
export function getStopsForRouteOLD() {
    console.log(BaseURL);

    const fakeStops = [
        {
            id: "13243",
            name: "First Stop"
        },
        {
            id: "66352",
            name: "Second Stop"
        },
        {
            id: "72821",
            name: "Third Stop"
        }
    ];

    const promise = new Promise((resolve) => {
        resolve({data: fakeStops});
    });

    return promise;
}

//
//
export function getDirections(route) {
    const data = createFormData({route});

    const url = `${BaseURL}/api/busDV/getBusDirectionsData`;

    var config = {
        method: 'post',
        url,
        data
    };

    return axios(config);
}
