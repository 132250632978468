
import { getStopName } from "./requests/LocationRequests";

const NameKey = "__LOCAL_DV_NAMES_KEY__";

let names = {
    RailStops: {},
    BusStops: {},
    LightRailStops: {},
    RailLines: {
        AC: "Atlantic City Line",
        AM: "Amtrak",
        BC: "Bergen County Line",
        GS: "Gladstone Branch",
        MC: "Montclair-Boonton Line",
        ME: "ME Line",
        MT: "Morristown Line",
        ML: "Main Line",
        NC: "North Jersey Coast Line",
        NE: "Northeast Corridor Line",
        PR: "Princeton Branch",
        PV: "Pascack Valley Line",
        RV: "Raritan Valley Line",
        SL: "Meadowlands Rail",
        ST: "Septa",
        SP: "Septa"
    }
}

function getList(type) {

    const list = loadFromLocalStorage();

    console.log("STATION LIST?", type, list);

    if(list){
        names = list;
    }

    if(type === "rail-stop"){
        return names.RailStops;
    }
    else if(type === "bus-stop"){
        return names.BusStops;
    }
    else if(type === "lightrail-stop"){
        return names.LightRailStops;
    }
    else if(type === "rail-line"){
        return names.RailLines;
    }

    return {};
}

export function setName(val, name, type){

    console.log("NAME MAPPING SET NAME", val, name, type);

    const listToUse = getList(type);
    listToUse[val.toUpperCase()] = name;

    console.log("LOCAL LIST TO USE", listToUse);

    console.log("LOCAL ALL LOCATIONS MAPPED", names, val, name, type);

    updateLocalStorage();
}

export function getName(val, type, callback, count = 0) {
    const listToUse = getList(type);

    console.log("GET NAME VALUE", val, callback, typeof(callback));

    let v = val;
    if(v && v.toUpperCase){
        v = v.toUpperCase();
    }

    let ret = listToUse[v];

    if(count === 0){
        // for testing only
        // ret = "";
    }

    console.log("GET NAME VALUE", ret, !ret, typeof(callback) === "function");

    if(!ret && typeof(callback) === "function"){
        if(count < 2){
            // first wait half a second to see if the location loading caught up
            setTimeout(() => {
                ret = getName(val, type, callback, count + 1);
                if(ret){
                    callback(ret);
                }
                else {
                    // do nothing, processing ends, the other path will be taken
                }
            }, 500);
        }
        else{
            // run lookup
            runLookupCall(val, type)
            .then(({data}) => {
                console.log("THE LOOKUP CALL RETURN", data);

                const stop = (data && data.stopName) ? data.stopName : data;
    
                callback(stop);
            });
    
            return "";
        }

    }

    if(type === "bus-stop" && !ret){
        ret = "STOP #" + val;
    }
    else if(type === "lightrail_stop"){
        console.log("LIGHTRAIL NAME TRUNCATE", ret);
        const index = ret.indexOf("Light Rail");
        ret = ret.substr(0, index);
    }

    console.log("GET NAME", val, type, ret, listToUse);

    return ret || "";
}

function loadFromLocalStorage(){
    let ret = null;

    const val = localStorage.getItem(NameKey);
    if(val){
        ret = JSON.parse(val);
    }

    return ret;
}

function updateLocalStorage(){
    console.log("UPDATE LOCAL STORAGE", names);
    const str = JSON.stringify(names);
    localStorage.setItem(NameKey, str);
}

//
//
function runLookupCall(val, type){
    // figure out which call I need to make
    if(type === "bus-stop" || type === "lightrail-stop"){
        return getStopName(val);
    }
    else{
        return {data: ""};
    }
}