var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageType === 'rail')?_c('button',{staticClass:"button",style:(`position: relative; 
        width: ${_vm.$isMobile ? '18vw' : 'clamp(3rem, 5vw, 10rem)'}; 
        height: ${_vm.$isMobile ? '18vw' : 'clamp(3rem, 5vw, 10rem)'};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #eee;
        `),on:{"click":_vm.gotoStops}},[_c('span',{style:(`font-size: ${_vm.$isSmallScreen ? 1.2 : 1.8}rem; font-weight: bold; opacity: 0.8;`)},[_vm._v(_vm._s(_vm.remove0(_vm.vehicleID)))]),_c('div',{style:(`width: 100%; height: 100%; opacity: 0.3;
        position: absolute;
        background-image: url(./rail_icons/${_vm.routeName}_icon.png); background-size: contain; background-position: center;
        filter: drop-shadow(0px 0px 10px black);`)})]):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","light":""},on:{"click":_vm.gotoStops}},[_vm._v(" "+_vm._s(_vm.routeName)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }