
These are the buttons which will be shown within the spacer element on landscape view

They will be based on the list in allLinks, excluding the current page

Clicking on one of these buttons will navigate to the home page of that type

<template>
    <div v-if="!$isSmallScreen || showNav" :class="'button-list' + (($isSmallScreen && $props.query.footer !== false) ? ' in-footer' : '') + (condensed ? ' condensed': '')">
        <v-btn v-for="link in links" :key="link"
            rounded
            :color="((link === pageType && query.dark === false) || (link !== pageType && query.dark !== false)) ? 'white': 'var(--primary-color)'"
            :dark="!(isStop && stopValue === '') && !(!isStop && locationValue === '-1') && ((link !== pageType && query.dark === false) || (link === pageType && query.dark !== false))"
            @click="navigate(link)"
        >
            <!-- switch to  -->
            <div :class="'link-name' + (link === pageType ? ' current' : '')">
                {{link === "lightrail" ? "light" +"\xa0" + "rail": link}}
                <!--
                <div v-if="link === pageType">
                    MAIN PAGE
                </div>
                -->
            </div>
        </v-btn>
    </div>
</template>

<script>
    import { onlyNonNullValues } from "@/Utilities";

    const allLinks = ["bus", "lightrail", "rail"];

    export default {
        name: "NavigationButtons",
        props: ["pageType", "query", "showNav", "condensed"],
        data: () => ({
            links: []
        }),
        created(){
            console.log("BUTTON PROPS", this.$props);
            this.setLinks();
        },
        watch: {
            pageType: function(/*newVal, oldVal*/) {
                this.setLinks();
            }
        },
        methods: {
            navigate(link) {
                console.log("NAVIGATE TO", link, this.$props.query.fontsize);
                console.log("ON COMPLETE");

                let queryCopy = {...this.$props.query};
                delete queryCopy.route;
                delete queryCopy.direction;
                delete queryCopy.stop;
                delete queryCopy.station;
                delete queryCopy.tripid;
                delete queryCopy.fromMap;
                delete queryCopy.fromHomeNearby;
                delete queryCopy.fromMap;
                delete queryCopy.vid;
                delete queryCopy.hidenav;

                queryCopy = onlyNonNullValues(queryCopy);

                console.log("QUERY COPY", queryCopy);

                this.$emit("onComplete");
                this.$router.push({
                    path: `/${link}/home`, 
                    query: queryCopy
                });
            },
            setLinks(){
                this.links = allLinks;
                // this.links = allLinks.filter(v => v !== this.$props.pageType);
            }
        }
    }

/*
        <button class="link" v-for="link in links" :key="link" @click="navigate(link)">
            <!-- switch to  -->
            <div class="link-name">
                {{link === "lightrail" ? "light" +"\xa0" + "rail": link}}
                <div v-if="link === pageType">
                    MAIN PAGE
                </div>
            </div>
        </button>
*/

</script>

<style scoped>
    .button-list {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-between;
        /* margin: 2vh 2vh 0; */
        height: 100%;
        padding: 0 6vw 0 0;
        gap: 1rem;
    }

    .condensed .button-list {
        justify-content: flex-end;
        gap: 2vw;
        padding: 0 2.5vw 0 0;
    }

    .mobile .button-list:not(.in-footer),
    .smallscreen .button-list:not(.in-footer) {
        flex-direction: column;
        gap: calc(3 * var(--mvh));
        align-items: initial;
        justify-content: space-between;
        /* margin: 2vh 2vh 0; */
        height: 100%;
        padding: calc(4 * var(--mvh)) calc(1.5 * var(--mvh)) calc(2 * var(--mvh));
    }

    .button-list.in-footer {
        padding: 0 2vw 1px;
        justify-content: space-evenly;
        /* background-color: var(--primary-color); */
        background-color: var(--footer-background);
        /* opacity: 0.8; */
    }

    .v-btn {
        height: 40px !important;
    }

    .condensed .v-btn {
        height: 32px !important;
    }

    .mobile .v-btn {
        height: 45px !important;
    }

    .mobile .button-list .v-btn {
        /* height: 55px !important; */
    }

    .link {
        background-color: var(--primary-color);
        color: white;
        border-radius: 0.3rem;
        /* padding: 0.4rem; */
        padding: 0.1rem 1.3rem;
        font-size: 1rem;
        border: 1px solid transparent;
        cursor: pointer;
        z-index: 1;
        /* margin-bottom: 2vh; */
        transition: 0.2s;
        height: 3.1rem;
        border-radius: 3.7rem;
    }

    .link:hover {
        background-color: #999;
        /* box-shadow: 0 0 1rem var(--primary-color); */
        border: 1px solid var(--primary-color);
    }

    .link:focus {
        outline: none;
    }

    .link:active {
        transform: scale(0.98);
    }

    .link-name {
        font-weight: bold;
        font-size: 1.3rem;
        text-transform: uppercase;
    }

    .in-footer .link-name {
        font-weight: 500;
        font-size: 1rem;
        color: var(--primary-color);
    }

    .in-footer .link-name.current {
        /* font-weight: bold; */
        font-size: 1.1rem;
        text-shadow: 0 0 1px var(--primary-color);
    }

    .dark .current {
        color: white;
    }

    .dark .button-list.condensed .v-btn:not(.v-btn--disabled) {
        border-color: white !important;
    }


    .link-name div {
        font-size: 0.8rem;
    }

    /* @media only screen and (max-width: 600px){
        .button-list {
            display: none;
        }
    } */
</style>