
<template>
    <div :class="'navbar' + (condensed ? ' condensed' : '')">
        <div v-if="!condensed">
            <NJT_Title />
        </div>
        <div v-else>
            <NJT_Title :condensed="true" :replacementText="text" :hideText="hideText" :query="query" :isTripsPage="isTripsPage" :fromMap="fromMap || query.fromMap" :isMapPage="isMapPage" :isStationPage="isStationPage" :lineDisplay="lineDisplay" />
        </div>
        <div style="display: flex; justify-content: flex-end;">
            <NavigationButtons v-if="!$isSignMode" :pageType="pageType" :query="query" :condensed="condensed" />
        </div>
    </div>
</template>

<script>
    import NJT_Title from "./NJT_Title";
    import NavigationButtons from "./NavigationButtons";

    export default {
        name: "LaptopNavBar",
        props: ["condensed", "pageType", "query", "pageObj", "text", "hideText", "isTripsPage", "fromMap", "isMapPage", "isStationPage", "lineDisplay"],
        components: {
            NJT_Title,
            NavigationButtons
        },
        watch: { 
            text: function(newVal, oldVal) { // watch it
                console.log('TEXT PROP CHANGED: ', newVal, ' | was: ', oldVal)
            }
        }
    }
</script>

<style scoped>
    .navbar {
        display: grid;
        grid-template-columns: 60vw 40vw;
        width: 100%;
        height: calc(15 * var(--mvh));
        /* background: rgba(0,0,0,0.2); */
    }

    .condensed {
        position: fixed;
        height: calc(9 * var(--mvh));
        border-bottom: 2px solid var(--primary-color);
    }

    .dark .condensed {
        background: var(--primary-color);
        color: white;
        border-bottom: none;
    }
</style>