/* eslint-disable no-extra-boolean-cast */
/*
    The rail data has its own API, with different call structure
    and different data schemas

    This is going to hide that from the outside by acting as an 
    adapter
*/

import axios from "axios";

import { GenerateAudioURL, createFormData, getCurrentStore, appendToKillSwitch, runSim } from "./BaseRequestValues";

// import axiosRetry from "axios-retry";

// const BaseURL = "https://testraildata.njtransit.com/api";
const BaseURL = "https://raildata.njtransit.com/api";

// const FAILURE_LIMIT = 60;

const TOKEN_KEY = "_*_*_*DV_RAIL_TOKEN*_*_*_"

// let consecutiveFailureCount = 0;
// let initialFailureTime = null;

//
//
let reqToken = null;

function viewReqToken(){
    if(!reqToken || reqToken.length < 10){
        reqToken = localStorage.getItem(TOKEN_KEY);
    }
    return reqToken;
}

function setReqToken(v){
    reqToken = v;
    localStorage.setItem(TOKEN_KEY, v);
}


// // retry the requests is they initially fail
// axiosRetry(axios, {
// 	retries: 0, // number of retries
// 	retryCondition: (error) => {
//         console.log("AN ERROR", error);
//         console.log("GET CURRENT VUE?", getCurrentStore(), error);

//         if(error.response){
//             console.log("AN ERROR DATA", error.response.data);
//         }

//         consecutiveFailureCount++;
//         if(!initialFailureTime){
//             initialFailureTime = Date.now();
//         }

//         console.log("CONSECUTIVE FAILURES RAIL", getCurrentStore(), consecutiveFailureCount);

//         // if(consecutiveFailureCount >= FAILURE_LIMIT){
//         if(failureDiff()){
//             // call to the store to set disconnected mode
//             // how do I call to the store from here?
//             // do I need to pass something in to use as a callback?
//             console.log("EXCEEDED CONSECUTIVE FAILURE LIMIT");
//             getCurrentStore().commit("setIsDisconnected", true);
//         }
// 		// if retry condition is not specified, by default idempotent requests are retried
// 		// return error.response.status === 503;

//         /*
// {
//   "errorMessage": "Invalid token."
// }
//         */

//         // make the retry probabilistic so it's sure to stop eventually no matter what
//         const r = Math.random();
// 		return (r > 0.2) && false;
// 	}
// });

// function failureDiff() {
//     const now = Date.now();

//     console.log("FAILURE DIFF", now, initialFailureTime, now - initialFailureTime);

//     if(!initialFailureTime){
//         return false;
//     }

//     const sec = Math.abs(now - initialFailureTime)/1000;
//     return sec >= FAILURE_LIMIT;
// }

//
// check if the current token is valid
//
function checkToken(){
    if(reqToken && reqToken.length >= 10){
        // return isValidToken()
        // .then(({data}) => {
        //     console.log("VALID TOKEN?", data);
        //     if(data.validToken){
        //         return new Promise((resolve) => {
        //             resolve(null);
        //         })
        //     }
        //     else{
        //         return getToken();
        //     }
        // });
        return new Promise((resolve) => {
            resolve(null);
        });
    }
    else{
        console.log("WE NEED TO GET A TOKEN", reqToken);
        return getToken();
    }
}

/*
//
//
function isValidToken(){
    // const url = `${BaseURL}/TrainData/isValidToken/${token}`;
    // return axios.get(url);

    const url = `${BaseURL}/TrainData/isValidToken`;

    const data = createFormData({token});

    const config = {
        method: "post",
        url,
        data,
        timeout: 2000
    }

    return axios(config);
}
*/

//
// get new token if one doesn't yet exist
//
function getToken() {
    // console.log("GET RAIL TOKEN");
    // const url = `${BaseURL}/TrainData/authenticateUser/test/test`;

    console.log("RUNNING THE GET TOKEN REQUEST");

    const url = `${BaseURL}/TrainData/getToken`;

    const formData = createFormData({
        username: "DV5",
        password: "!V1510n!"
    });

    const config = {
        method: "post",
        url,
        data: formData,
        timeout: 5000
    }

    // return axios.get(url)
    return axios(config)
    .then(({data}) => {
        console.log("WE HAVE A NEW TOKEN", data);
        setReqToken(data.UserToken)
        resetErrors();
    })
    .catch(err => console.error(err));
}

//
//
function resetErrors() {
    // consecutiveFailureCount = 0;
    // initialFailureTime = null;
    getCurrentStore().commit("setIsDisconnected", false);
}

//
//
function getRetryTimeout(rt){
    console.log("RETRY TIMEOUT", rt);
    if(rt === 0){
        return 2000;
    }
    else if(rt === 1 || rt === -1){
        return 4000;
    }
    else if(rt === 2) {
        return 10000;
    }
    else if(rt > 2) {
        return 20000;
    }
}

/*

so how would I cut off multiple retries of the same method,
or else cut off a retry if a new method call comes in?

new requests will always come in with reAttemptCount = 0?

// keep a listing of the timeouts for each method
// --> pass in a name for each call
// --> whenever there's a retry, add the named interval to the list along with its timeout length

// if a call completes successfully, kill all other intervals for that method and fully clear list

// if a zero comes in, then kill all other intervals for that method and fully clear the list
// --> *before* adding the new request to the list, obviously

// if a non-zero call comes in
// --> if it has the lowest retry count, kill all other intervals and clear them from list
// --> if it doesn't have the lowest retry count, don't make the call or add to list

// weird edge case: multiple successful calls going back and forth out of phase?
// if we have an unresolved zero call in place already, don't make another call or add to list

// when a call resolves unsuccessfully, need to remove it from the list
// --> how do I identify this?
// --> what are the scenarios here
// --> well, if it wasn't killed off by this point, then it must have been the least retry
// --> and so it would have been the only one there
// --> in which case I fully clear the list and then add this new upcoming retry again

{
    name: "",
    attempt: "",
    interval: ""
}

*/

const requestTracker = {};

const ZERO_CALL_LIMIT = 5000;

//
//
function runRequest(req, name, reAttemptCount = 0){

    const r = Math.floor(Math.random()*100000);

    const prereq = requestTracker[name];

    console.log("RAIL REQUEST ENTRY POINT; RUNNING", Date.now(), r, name, reAttemptCount, prereq, requestTracker);

    if(reAttemptCount === 0){
        const now = Date.now();

        console.log("NEW REQUEST CAME IN", r, name, reAttemptCount, prereq);

        if(prereq && prereq.reAttemptCount !== 0){
            console.log("1 REQUEST KILLED A TIMEOUT FOR", r, name, reAttemptCount);
            killExistingRequest();
        }
        else if(prereq && ((now - prereq.time) < ZERO_CALL_LIMIT)){
            // let the other 0 attempt go on its own
            console.log("1 REQUEST KILLED A REQUEST FOR", r, name);
            return;
        }

        requestTracker[name] = {name, reAttemptCount, interval: null, time: now};
    }
    else if(prereq && prereq.reAttemptCount <= reAttemptCount){
        // don't call anything at all, let the other request continue
        // since it's on the same or lesser level already
        console.log("2 REQUEST KILLED A REQUEST FOR",r , name);
        return;
    }
    else if(prereq && prereq.reAttemptCount > reAttemptCount){
        console.log("HOW IS THIS ONE POSSIBLE? 2 KILLED A REQUEST FOR", r, name);
        // const interval = prereq.interval;
        // if(interval){
        //     console.log("2 KILLED A TIMEOUT FOR", name, reAttemptCount);
        //     clearTimeout(interval);
        // }
    }

    //
    //
    // PROCEED
    //
    //

    const timeout = getRetryTimeout(reAttemptCount);
    const nextAttempt = reAttemptCount >= 0 ? reAttemptCount + 1 : -2;

    return checkToken()
    .then(() => {
        const rt = viewReqToken();
        if(!rt || rt.length < 10){
            console.log("REQUEST HOW DID WE GET HERE? BAD TOKEN?", r, rt);
            // do we actually want to do this?
            requestTracker[name] = null;
            // something very weird has happened here...
            setReqToken(null);
            attemptRequestAgain(req, name, reAttemptCount, nextAttempt, timeout);
            return;
        }

        console.log("REQUEST FINISHED CHECKING TOKEN; RUNNING", r, name, reAttemptCount, requestTracker);
        // this is the normal execution of the request
        // reset the failure count

        const controller = new AbortController();
        const signal = controller.signal;

        requestTracker[name].controller = controller;
        requestTracker[name].interval = null;

        return req(rt, signal)
        .then(res => {
            // if we're successful, reset the tracker for this method
            killExistingRequest(name);
            requestTracker[name] = null;
            return res;
        })
        .catch(err => {
            console.log("REQUEST CATCH 1", r, name, reAttemptCount, nextAttempt, timeout);
            catchRequestError(err, req, name, reAttemptCount, nextAttempt, timeout);
        });
    })
    .catch(err => {
        console.log("REQUEST CATCH 2", r, name, reAttemptCount, nextAttempt, timeout);
        catchRequestError(err, req, name, reAttemptCount, nextAttempt, timeout);
    });
}

//
//
function cancelAllActiveRequests(){
    Object.keys(requestTracker).forEach(key => {
        killExistingRequest(key);
    });
}

appendToKillSwitch(cancelAllActiveRequests);

//
//
function killExistingRequest(name){
    // either end the timeout or abort the request

    const req = requestTracker[name];

    console.log("KILL EXISTING SIM REQUEST", name, requestTracker);

    if(req && req.interval){
        clearTimeout(req.interval);
    }
    else if(req && req.controller){
        try{
            console.log("ABORT SIM");
            req.controller.abort();
        }
        catch(error){
            console.log("ERROR WHILE TRYING TO ABORT", error);
        }
    }
}

//
//
function attemptRequestAgain(req, name, reAttemptCount, nextAttempt, timeout){

    console.log("ATTEMPT REQUEST AGAIN", name, reAttemptCount);

    if(reAttemptCount >= 0 && reAttemptCount <= 5) {
        const interval = window.setTimeout(() => {
            console.log("OTHER ERROR RETRY!!!");
            runRequest(req, name, nextAttempt);
        }, timeout);
        requestTracker[name] = {name, nextAttempt, interval};
    }
}

//
//
function catchRequestError(err, req, name, reAttemptCount, nextAttempt, timeout){
    console.log("CAUGHT REQUEST EXCEPTION", err);

    killExistingRequest(name);
    // do we actually want to do this?
    requestTracker[name] = null;

    let result = "";
    if(err.response){
        result = err.response.data;
        console.log("CAUGHT EXCEPTION REQUEST RESULT", result, err);
    }

    if(result && result.errorMessage === "Invalid token."){
        // clear it so there's no confusion
        console.log("WE HAVE AN INVALID REQUEST TOKEN", reAttemptCount);

        setReqToken(null);
    }

    attemptRequestAgain(req, name, reAttemptCount, nextAttempt, timeout);
}

//
// get the list of stations
//
function getRailStations(){
    console.log("GET RAIL STATIONS");
    const req = (token, signal) => {
        // const url = `${BaseURL}/TrainData/getStationList/${token}`;
        // return axios.get(url)

        const url = `${BaseURL}/TrainData/getStationList`;

        const formData = createFormData({token});

        const config = {
            method: "post",
            url,
            data: formData,
            timeout: 5000, 
            signal
        };

        const choice = false;

        if(choice){

            setTimeout(() => {
                console.log("KILL THE SIM REQUEST");
                killExistingRequest("getStationList");
            }, 3000);

            console.log("RUNNING THE SIM REQUEST");

            return runSim(token, 5000, 100, signal)
            .then((res) => {
                console.log("SIM REQUEST RUN SUCCESSFULLY!", res);
            });
        }
        else{
            return axios(config)
            .then(({data}) => {
    
                resetErrors();
                storeStations(data);
    
                return {
                    data: formatStations(data)
                };
            });
        }
    };

    return runRequest(req, "getStationList");
}

const RAIL_STATIONS_KEY = "__RAIL_STATIONS_STORAGE__"

function storeStations(data){

    const lookup = {};

    data.forEach(stat => {
        if(stat.STATION_2CHAR){
            lookup[stat.STATION_2CHAR] = stat.STATIONNAME;
        }
    });

    console.log("RAIL STATIONS MAP", lookup);

    const str = JSON.stringify(lookup);
    localStorage.setItem(RAIL_STATIONS_KEY, str);
}

export function retrieveStations(){
    const str = localStorage.getItem(RAIL_STATIONS_KEY);
    return JSON.parse(str);
}

//
//
//
function getAudioMessage(ip) {

    const requestURL = GenerateAudioURL(ip);
    return axios.get(requestURL);

    // const ret = {
    //     enable_scrolling: false,
    //     message: "I am a message that should be scrolling on the screen",
    //     duration: Math.ceil(Math.random() * 8)
    // };

    // ret.message = ret.message.repeat(ret.duration);

    // if(Math.random() > 0.7) {
    //     ret.enable_scrolling = true;
    // }

    // const promise = new Promise((resolve) => {
    //     resolve({
    //         data: ret
    //     });
    // });

    // return promise;
}

//
// get the next 19 trips for this station
//
function getRailTrips(params){
    console.log("GET RAIL TRIPS PARAMS", params);
    const req = (token, signal) => {
        console.log("GET RAIL TRIPS", params, token);
        const stationId = params.station;
        // const status = params.status || "n";
        const route = params.route || null;
        // const tripurl = `${BaseURL}/SignData/getTrainScheduleSign/${token}/station/${stationId}/status/${status}/includeDeleted/false${route ? `/line/${route}` : ''}`;
        
        let tripurl = `${BaseURL}/TrainData/getTrainSchedule19Rec`;
        let tripData = null;

        console.log("IS LATE?", params.late, token);

        if(params.late) {
            const NJTOnly = params.late === "true" || params.late === true || params.late === "njt";

            console.log("IS NJT ONLY?", NJTOnly, params.late, params.late === true);

            tripurl = `${BaseURL}/SignData/getLateTrain`;
            tripData = createFormData({
                token,
                NJTOnly,
                // ip: params.sign
            });
        }
        else if(params.status && params.status !== "N" && params.status !== "n"){

            tripurl = `${BaseURL}/SignData/getTrainScheduleSign`;

            const tripParams = {
                token,
                station: stationId,
                includeDeleted: false,
                line: route,
                status: params.status ? params.status.toUpperCase(): "N"
            };

            console.log("RAIL STATUS KEY", params.status, tripParams);

            tripData = createFormData(tripParams);
        }
        else{
            const tripParams = {
                token,
                station: stationId,
                // ip: params.sign
            };

            if(route){
                tripParams.line = route;
            }

            console.log("TRIP PARAMS", tripParams);

            tripData = createFormData(tripParams);
        }

        console.log("GET RAIL TRIPS PARAMS", params);

        const tripConfig = {
            method: "post",
            url: tripurl,
            data: tripData,
            timeout: 5000,
            signal
        };

        console.log("GET RAIL TRIPS URL", tripurl);

        // const messageurl = `${BaseURL}/Signdata/getTrainScheduleSign/${token}/station/${stationId}/status/${status}`; // /includeDeleted/true
/*
        const messageurl = `${BaseURL}/TrainData/getStationMSG`;

        const messageData = createFormData({
            token, 
            station: stationId
            // would we include the line?
        });

        const messageConfig = {
            method: "post",
            url: messageurl,
            data: messageData,
            timeout: 5000
        };
*/

        return Promise.allSettled([
            // axios.get(tripurl),
            // axios.get(messageurl)
            axios(tripConfig),
            // axios(messageConfig)
        ])
        .then((results) => {

            console.log("TRIPS RESULTS", results);

            resetErrors();
            const tripData = results[0].status === "fulfilled" ?  results[0].value.data : [];
            // const messageData = results[1].status === "fulfilled" ?  results[1].value.data.STATIONMSGS : [];
            // const messageData = results[1].status === "fulfilled" ?  results[1].value.data : [];
            const messageData = results[0].status === "fulfilled" 
                                ? results[0].value.data.STATIONMSGS 
                                : [];

            console.log("RAIL TRIP RESPONSE", results, tripData);
            console.log("RAIL TRIPS FORMATTED", formatTrips(tripData));
            console.log("RAIL MESSAGE RESPONSE", messageData, results[1]);

            let emergencyMessages = [];

            if(results[0].status !== "fulfilled"){
                const res1 = results[0].reason.response.data.errorMessage;
                throw new Error(res1);
            }

            if(messageData && messageData.length > 0){
                messageData.forEach(message => {
                    if(message.MSG_TYPE === "fullscreen" /*|| message.MSG_TYPE === "banner"*/){
                        emergencyMessages.push(message);
                    }
                });
            }

            console.log("PROCESS THE TRIPS?", emergencyMessages);

            return {
                data: {
                    DVTrip: emergencyMessages.length > 0 ? [] : formatTrips(tripData),
                    message: emergencyMessages.length > 0 ? formatMessages(emergencyMessages) : formatMessages(messageData)
                }
            };
        })
        .catch(err => {
            console.log("GET TRIPS ERROR", err);
        });
    };
    // return runRequest(req, "getTrainSchedule19Rec", -1);

    return runRequest(req, "getTrainSchedule19Rec");
}

//
// get the stops for this trip
//
function getRailStops(params){
    console.log("GET RAIL STOPS", params);
    console.log("GET RAIL STOPS", params);
    console.log("GET RAIL STOPS", params);
    const req = (token, signal) => {

        if(!token){
            throw new Error("Rail Stop Missing Token");
        }

        const trainId = params.tripid;
        // const url = `${BaseURL}/TrainData/getTrainStopList/${token}/trainid/${trainId}`;
        // return axios.get(url)
        
        const url = `${BaseURL}/TrainData/getTrainStopList`;

        const formData = createFormData({
            token,
            train: trainId
        });

        const config = {
            method: "post",
            url,
            data: formData,
            timeout: 5000,
            signal
        }

        return axios(config)
        .then(({data}) => {
            resetErrors();
            return {
                data: formatStops(data)
            };
        });
    };
    return runRequest(req, "getTrainStopList");
}

/*
    lat, lng, radius
*/
//
//
function getRailVehicleLocations(params) {
    console.log("GET RAIL VEHICLE LOCATIONS", params);
    const {lat, lng, radius} = params;
    const req = (token, signal) => {
        const url = `${BaseURL}/GetData/getTrainsNearBy`;

        const data = createFormData({
            LAT: lat,
            LON: lng,
            RAD: radius, 
            token
        });

        const config = {
            url,
            data,
            method: "POST",
            timeout: 2000,
            signal
        };

        return axios(config)
        .then(({data}) => {
            resetErrors();
            return {
                data: formatVehicleLocations(data)
            };
        });
    };
    // return runRequest(req, "getTrainsNearBy", -1);
    return runRequest(req, "getTrainsNearBy");
}

//
//
function getRailStopLocations(params) {
    console.log("GET RAIL STOP LOCATIONS", params);
    const {lat, lng, radius} = params;
    const req = (token, signal) => {
        const url = `${BaseURL}/GetData/GetClosestStation`;

        const data = createFormData({
            LAT: lat,
            LON: lng,
            RAD: radius, 
            token
        });

        const config = {
            url,
            data,
            method: "POST",
            timeout: 2000,
            signal
        };

        return axios(config)
        .then(({data}) => {

            console.log("STOP LOCATION REQUEST RESULTS", data);

            resetErrors();

            // what is this about right here?
            return {
                data: formatStopLocations(data)
            };

            // return {
            //     data: {
            //         trip: {
            //             id: data.TRAIN_ID,
            //             route: data.LINECODE,
            //             BACKCOLOR: data.BACKCOLOR,
            //             FORECOLOR: data.FORECOLOR
            //         },
            //         stops: formatStopLocations(data)
            //     }
            // };
        });
    };
    return runRequest(req, "GetClosestStation");
}

function getStationToStation(params){
    const req = (token, signal) => {
        const url = `${BaseURL}/GetData/getPlanTrip`;

        const now = new Date();
        const date = `${now.getMonth()+1}-${now.getDate()}-${now.getFullYear()}`;
        const time = `${now.getHours() < 10 ? "0" + now.getHours() : now.getHours()}${now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()}`;

        const data = createFormData({
            token,
            from: params.from,
            to: params.to,
            date,
            time,
            Arrdep: "D"
        });

        const config = {
            url,
            data,
            method: "POST",
            signal
        }

        return axios(config)
        .then(({data}) => {
            resetErrors();
            return {
                data
            };
        });
    };

    return runRequest(req, "getPlanTrip");
}

//
// RAIL PATH MAPPING
//

//
//
function getIndividualTrainLocation(params) {
    const req = (token, signal) => {

        console.log("INDIVIDUAL LOCATION PARAMS", params, token);

        const url = `${BaseURL}/SignData/getTrainMap`;

        const data = createFormData({
            token,
            trainid: params.trainid
        }, "getIndividualTrainLocation");

        const config = {
            url,
            data, 
            method: "POST",
            signal
        }

        return axios(config)
        .then(({data}) => {
            console.log("RAW INDIVIDUAL TRAIN", data);
            resetErrors();
            return {data: formatIndividualTrain(data, params.lineCode)};
        });
    }

    return runRequest(req, "getIndividualTrainLocation");
}

//
//
function getTrainPath(params) {
    const req = (token, signal) => {

        params.token = token;

        const url = `${BaseURL}/GetData/getAllLatLonLineCode`;
        console.log("GET LINE TRAIN PATH REQUEST", params, token, url);

        const data = createFormData(params, "getTrainPath");

        const config = {
            url,
            data, 
            method: "POST",
            signal
        }

        return axios(config)
        .then(({data}) => {
            console.log("RAW PATHS", data);
            resetErrors();
            return {data: formatTrainPath(data)};
        });
    };

    return runRequest(req, "getTrainPath");
}

//
//
function getLineStationLocations(params) {

    const req = (token, signal) => {

        params.token = token;

        const url = `${BaseURL}/GetData/getAllStationsList`;

        const data = createFormData(params, "getLineStationLocations");

        const config = {
            url,
            data,
            method: "POST",
            signal
        };

        return axios(config)
        .then((res) => {
            console.log("GET LINE INNER", res);
            resetErrors();
            return {data: formatLineStations(res.data, params.line)};
        });
    };

    return runRequest(req, "getLineStationLocations" + params.line);
}

const allLines = ["AC", "BC", "GS", "MC", "ME", "ML", "NC", "NE", "PR", "PV", "RV"];
const lineColors = {
    "AC": "", 
    "BC": "", 
    "GS": "",
    "MC": "",
    "ME": "",
    "ML": "",
    "NC": "",
    "NE": "",
    "PR": "",
    "PV": "",
    "RV": ""
};

function getAllLineMapInfo() {
    console.log("GET MAP INFO INNER 1");
    // call Crystal's method for the paths (should get all at once)
    const pathRequest = getTrainPath({line: "all"});
    console.log("GET MAP INFO INNER 2");
    
    const allRequests = [pathRequest];

    // call each of the station info methods
    /*
        Atlantic City Line	AC
        Bergen County Line	BC
        Gladstone Branch	GS
        Montclair-Boonton Line	MC
        Morris & Essex Line	ME
        Main Line	ML
        North Jersey Coast Line	NC
        Northeast Corridor Line	NE
        Princeton Branch	PR
        Pascack Valley Line	PV
        Raritan Valley Line	RV
    */

    allLines.forEach(l => {
        allRequests.push(getLineStationLocations({line: l}));
    });

    console.log("GET MAP INFO INNER 3");

    // group these into a promise allSettled
    return Promise.allSettled(allRequests)
    .then(res => {
        console.log("GET MAP INFO RETURN", res);

        if(res && res.length){
            // check whether we got the results back correctly
            let error = false;
            res.forEach(r => {
                if(r.status !== "fulfilled"){
                    error = true;
                }
            });

            if(error){
                return {data: null};
            }

            // format the results
            const ret = {
                data: {                    
                    paths: res[0].value.data,
                    lineColors,
                    stations: {}
                }
            };

            for(let i = 1; i < res.length; i++){
                console.log("STATIONS HERE", res[i]);
                console.log("STATIONS HERE INNER", res[i].value.data, res[1].value.data[0].line);
                ret.data.stations[res[i].value.data[0].line] = res[i].value.data;
            }
            
            // return the results
            return ret;
        }
        else{
            return {data: null};
        }
    });
}

//
// any other ones aren't yet implemented
//


/////////////////////////////////////////////////
/////////////////////////////////////////////////
//
// FORMATTING
//
/////////////////////////////////////////////////
/////////////////////////////////////////////////


//
//
function formatStations(stationData){
    console.log("STATION DATA", stationData);
    /*
        STATIONNAME: "Absecon"
        STATION_2CHAR: "AB"

        ==>

        valueField="bus_terminal_code"
        displayField="bus_terminal_name"
    */
    return stationData.map(station => {
        return {
            bus_terminal_code: station.STATION_2CHAR,
            bus_terminal_name: station.STATIONNAME
        }
    });
}

const baseIconPath = "./rail_icons";

//
//
function formatTrips(tripData){

    console.log("TRIP DATA", tripData);

    const tripsToUse = tripData.ITEMS ? tripData.ITEMS : tripData;

    if(!Array.isArray(tripsToUse)){
        console.log("RETURN EMPTY TRIP DATA ARRAY");
        return [];
    }

    return tripsToUse.map(trip => {
        return {
            public_route: "",
            linecode: trip.LINECODE, // for now
            header: trip.DESTINATION || trip.LINE,
            line_fullname: trip.LINE,
            line_abbr: trip.LINEABBREVIATION,
            sched_dep_time: trip.SCHED_DEP_DATE || trip.SCHED_DEP_DATE_Origin,
            lanegate: trip.TRACK,
            busid: trip.TRAIN_ID,
            internal_trip_number: trip.TRAIN_ID,
            passload: formatPassengers(trip.CAPACITY),
            departuretime: trip.STATUS,
            BACKCOLOR: trip.BACKCOLOR,
            FORECOLOR: trip.FORECOLOR,
            icon: `${baseIconPath}/${(trip.LINECODE === 'SP' ? 'ST' : trip.LINECODE)}_icon.png`,
            message: trip.INLINEMSG
        };
    });
}

//
//
function formatPassengers(capacity) {
    const carColors = [];


    const testOnly = false;

    if(testOnly){
        return [
            {color: "rgb(11, 102, 35);", num: "1234", position: 1, in: 1, rest: true},
            {color: "rgb(11, 102, 35);", num: "9876", position: 2, in: 2},
            {color: "rgb(11, 102, 35);", num: "5555", position: 3, in: 3},
            {color: "rgb(11, 102, 35);", num: "5555", position: 4, in: 3},
            {color: "rgb(11, 102, 35);", num: "5555", position: 5, in: 3, rest: true},
            {color: "rgb(11, 102, 35);", num: "5555", position: 6, in: 3, rest: true},
            {color: "rgb(11, 102, 35);", num: "5555", position: 7, in: 3, rest: true},
            {color: "rgb(11, 102, 35);", num: "5555", position: 8, in: 3},
        ];
    }



    if(!capacity || !capacity.length){
        return carColors;
    }

    console.log("CAPACITY FORMATTING BEFORE", capacity);

    capacity.length && capacity[0].SECTIONS && capacity[0].SECTIONS.forEach(s => {
        let inSec = 0;

        if(s.SECTION_POSITION.toUpperCase() === "FRONT"){
            inSec = 0;
        }
        else if(s.SECTION_POSITION.toUpperCase() === "MIDDLE"){
            inSec = 1;
        }
        else {
            inSec = 2;
        }

        s.CARS.forEach(c => {
            carColors.push(
                {
                    color: c.CUR_CAPACITY_COLOR, 
                    num: c.CAR_NO , 
                    position: c.CAR_POSITION, 
                    firstInCar: false,
                    in: inSec, 
                    rest: c.CAR_REST
                }
            );
        });
    });

    // if(carColors.length > 0){
    //     while(carColors.length < 11){
    //         carColors.push(carColors[0]);
    //     }
    // }

    // put them in position order?
    carColors.sort((a, b) => {
        if(a.in < b.in || (a.in === b.in && a.position < b.position)) {
            return -1;
        }
        else if(a.position === b.position){
            return 0;
        }
        else {
            return 1;
        }
    });

    // mark which is the first in each section
    let section = -1;
    carColors.forEach(cc => {
        if(cc.in > section){
            cc.firstInCar = true;
            section++;
        }
    });

    console.log("CAPACITY FORMATTING AFTER", carColors);

    return carColors;

    // return [
    //     {color: "red", num: "543", position: 3},
    //     {color: "yellow", num: "1321", position: 2},
    //     {color: "red", num: "4538", position: 1}
    // ];
}

//
//
function formatStops(stopData){
    console.log("STOP DATA", stopData);

    const ret = {
        trip: {
            BACKCOLOR: stopData.BACKCOLOR,
            FORECOLOR: stopData.FORECOLOR,
            route: stopData.LINECODE,
            tripId: stopData.TRAIN_ID,
        },
        stops: null,
        destination: stopData.DESTINATION
    }
/*

    STOPS: Array(10)
        0:
        DEPARTED: "NO"
        DEP_TIME: "22-Aug-2021 11:33:45 PM"
        DROPOFF: ""
        PICKUP: ""
        STATIONNAME: "Bay Head"
        STATION_2CHAR: "BH"
        STOP_LINES: []
        STOP_STATUS: ""
        TIME: "22-Aug-2021 11:

        ==>

        Description
        SchedDepTime


        <div class="name">{{$props.stop.Description}}</div>
            </div>
            <div class="stop-last">
                <div v-if="$props.stop.isDeparted" class="departed">
                    DEPARTED
                </div>
                <div v-else class="time">
                    {{time}}
                </div>

*/

    if(!stopData.STOPS || !stopData.STOPS.length){
        ret.stops = null;
        return ret;
    }

    ret.stops = stopData.STOPS.map(stop => {
        const formatted = {
            Description: stop.STATIONNAME ,
            SchedDepTime: stop.TIME,
            transfers: [],
            UTC: stop.TIME_UTC_FORMAT,
            Status: (stop.DEPARTED === "YES") ? "DEPARTED" : (stop.STOP_STATUS ? stop.STOP_STATUS.toUpperCase() : "")
        }

        if(stop.DROPOFF) {
            formatted.Description += " - discharge only";
        }
        else if(stop.PICKUP) {
            formatted.Description += " - pickup only";
        }

        if(stop.STOP_LINES && stop.STOP_LINES.length > 0){
            formatted.transfers = stop.STOP_LINES.map(sl => {
                return {
                    route: sl.LINE_CODE,
                }; 
            });
        }

        return formatted;
    });

    console.log("STOP DATA FORMATTED", ret);

    return ret;
}

//
//
function formatVehicleLocations(vehicles){

/*
  {
    "TRAIN_ID": "string",
    "DISTANCE": "string",
    "LATITUDE": "string",
    "LONGITUDE": "string",
    "BACKCOLOR": "string",
    "FORECOLOR": "string",
    "SHADOWCOLOR": "string",
    "LINE": "string",
    "LINECODE": "string",
    "LINEABBREVIATION": "string",
    "DEST_2CHAR": "string",
    "DESTINATION": "string"
  }

  ==>

VehicleDestination: "5 NEWARK PENN STATION"
VehicleDistanceMiles: "4629.5353200755335"
VehicleID: "6429"
VehicleLat: "40.73215103149414"
VehicleLong: "-74.18132019042969"
VehiclePassengerLoad: "EMPTY"

*/

    console.log("RAW VEHICLE DATA", vehicles);

    return vehicles.map(v => {
        return {
            VehicleDestination: v.DESTINATION,
            VehicleDistanceMiles: v.DISTANCE,
            VehicleRoute: v.LINECODE,
            VehicleID: v.TRAIN_ID,
            VehicleLat: v.LATITUDE,
            VehicleLong: v.LONGITUDE,
            VehiclePassengerLoad: "",
            Direction: v.DIRECTION
        };
    });
}

//
//
function formatIndividualTrain(data, lineCode) {

    console.log("FORMAT INDIVIDUAL TRIP", data);

    /*
    "TRAIN_ID": "string",
    "DIRECTION": "string",
    "TRAINLINE": "string",
    "LAST_MODIFIED": "string",
    "BACKCOLOR": "string",
    "TRACKCKT": "string",
    "GPSLATITUDE": "string",
    "GPSLONGITUDE": "string",
    */

    const ret = [];

    ret.push({
        VehicleDestination: "",
        VehicleDistanceMiles: "",
        VehicleRoute: lineCode,
        VehicleID: data.TRAIN_ID,
        VehicleLat: data.GPSLATITUDE,
        VehicleLong: data.GPSLONGITUDE,
        VehiclePassengerLoad: ""
    });

    console.log("INDIVIDUAL FORMATTED", ret);

    return ret;
}

//
//
function formatStopLocations(stops){

/*

{
    "STATIONNAME": "string",
    "STATION_CODE": "string",
    "DISTANCE": "string",
    LAT?
    LON?
}

    ==>

    busstopdescription: "16TH AVE AT 6TH ST"
    busstopnumber: "19170"
    distance: "355"
    latitude: "40.734890"
    longitude: "-74.198720"

*/

    return stops.map(s => {
        return {
            busstopdescription: s.STATIONNAME,
            busstopnumber: s.STATION_CODE,
            distance: s.DISTANCE,
            latitude: s.LATITUDE,
            longitude: s.LONGITUDE
        };
    });
}

//
//
function formatMessages(messages){

/*
    {
        "MSG_TYPE": "string",
        "MSG_TEXT": "string",
        "MSG_PUBDATE": "string",
        "MSG_ID": "string",
        "MSG_AGENCY": "string"
        "MSG_SOURCE": "string"
    }

    ==>

    message
*/

// hmm, for now I'll just take the first...
// do I want to conglomerate these?

    // return messages.map(message => {
    //     return {
    //         v: message.x
    //     };
    // });

    const ret = {
        message: "",
        isEmergency: false
    };

    if(!messages){
        return ret;
    }

    // if(messages.length === 1 && messages[0].MSG_TEXT.indexOf("Thank you for riding") === 0){
    //     ret.message = messages[0].MSG_TEXT;
    //     ret.isEmergency = messages[0].MSG_TYPE === "fullscreen";
    // }
    // else if(messages.length > 1){
    //     ret.message = messages.map(m => {
    //         ret.isEmergency |= m.MSG_TYPE === "fullscreen";
    //         return {
    //             message: m.MSG_TEXT
    //         };
    //     });
    // }

    console.log("RAIL MESSAGES", messages);

    const filteredMessages = [];

    messages.forEach(m => {

        // "MSG_SOURCE": "RSS_NJTRailAlerts"
        if(m.MSG_SOURCE === "RSS_NJTRailAlerts"){
            return;
        }

        ret.isEmergency |= m.MSG_TYPE === "fullscreen";
        filteredMessages.push({ message: m.MSG_TEXT });
    });

    ret.message = filteredMessages;

    // random test content
    // ret.message = [];
    // ret.message.push({message: "ABCDrt re erg dsg g g d a gr gaf bafbafbafb rb aefb fbr  b ar rb rB gfk y y y wrbrBw rb rb ern ern ae aet ten t"});    
    // ret.message.push({message: "QQQQQQQQ re erg dsg g g d a gr gaf bafbafbafb rb aefb fbr  b ar rb rB gfk y y y wrbrBw rb rb ern ern ae aet ten t"});

    // ret.message = [{message: "Thank you for riding NJ Transit"}];
    
    return ret;
}

//
//
function formatTrainPath(path){

    /*
    [
        {
            "LINE_ID": "74800",
            "LATITUDE": "40.74913",
            "LONGITUDE": "-73.92385",
            "TRAIN_LINE": "NE",
            "COLOR": "#F7505E"
        },
        {
            "LINE_ID": "74900",
            "LATITUDE": "40.74786",
            "LONGITUDE": "-73.92926",
            "TRAIN_LINE": "NE",
            "
        }
    ]
    */

    if(path.length){

        console.log("GET LINE FORMAT TRAIN PATH", path);

        
        const ret = [];
        
        let currentLine = "";
        let linePath = null;

        path.forEach(p => {
            if(p.TRAIN_LINE !== currentLine){
                if(linePath){
                    ret.push(linePath);
                }
                linePath = {
                    line: p.TRAIN_LINE,
                    color: p.COLOR,
                    coordinates: []
                };

                // for the global view
                lineColors[p.TRAIN_LINE] = p.COLOR;

                currentLine = p.TRAIN_LINE;
            }

            linePath.coordinates.push({
                lat: parseFloat(p.LATITUDE),
                lng: parseFloat(p.LONGITUDE)
            });
        });

        return ret;
    }
    
    return [];
}

//
//
function formatLineStations(stations, lineName) {

    /*
    [
        {
            "STATION_2CHAR": "ED",
            "STATIONNAME": "Edison",
            "LATITUDE": "40.5192",
            "LONGITUDE": "-74.4109",
            "LINECODE": "NE"
        },
        {
            "STATION_2CHAR": "EZ",
            "STATIONNAME": "Elizabeth",
            "LATITUDE": "40.67063333",
            "LONGITUDE": "-74.21276667",
            "LINECODE": "NE"
        },
    ]
    */

    if(stations.length){
        const ret = [];

        stations.forEach(s => {
            ret.push({
                line: lineName,
                name: s.STATIONNAME,
                coordinates: {
                    lat: parseFloat(s.LATITUDE),
                    lng: parseFloat(s.LONGITUDE)
                }
            });
        });

        return ret;
    }

    return [];
}

//
//
//
export default {
    getAudioMessage,
    getRailStations,
    getRailTrips,
    getRailStops,
    getRailVehicleLocations,
    getIndividualTrainLocation,
    getRailStopLocations,
    getStationToStation,
    getTrainPath,
    getLineStationLocations,
    getAllLineMapInfo,
    cancelAllActiveRequests
};
