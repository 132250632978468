import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const prefixes = ["/bus", "/lightrail", "/riverline", "/rail"];

/*
  Apply the above prefixes to the paths of each of the routes below,
  since within each transportation mode there will be the same routes
*/
function prefixRoutes(prefixes, routes) {
  let processedRoutes = [];

  prefixes.forEach(prefix => {
    const newRoutes = routes.map(route => {
      const copy = {...route};
      copy.path = prefix + route.path;
      return copy;
    });

    processedRoutes = [...processedRoutes, ...newRoutes];
  });

  console.log("PREFIX ROUTES", processedRoutes);

  return processedRoutes;
}

const routes = [
  ...prefixRoutes(prefixes, 
    [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/trips',
        name: 'Trips',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Trips.vue')
      },
      {
        path: '/stops',
        name: 'Stops',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Stops.vue')
      },
      {
        path: '/map',
        name: 'Map',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Map.vue')
      },
      {
        path: '/station',
        name: 'Station',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/StationToStation.vue')
      },
      {
        path: '/pathinfo',
        name: 'PathInfo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PathInfo.vue')
      }
    ]
  )
];

routes.push(
  {
    path: "*",
    redirect: "/"
  }
);

routes.push({
  path: "/",
  component: () => import('../views/Landing.vue')
});

const router = new VueRouter({
  routes,
  scrollBehavior(/*to, from, savedPosition*/) {
    return { x: 0, y: 0 };
  }
})

export default router
