import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueMq from 'vue-mq';

import cloneDeep from 'lodash.clonedeep';

import Vuex from 'vuex';

// use fragments like in React
import frag from 'vue-frag';
import vuetify from './plugins/vuetify';
Vue.directive('frag', frag);

Vue.config.productionTip = false

// VueMq allows you to specify breakpoints by name + px width

/*
  determine breakpoints

  1100 x 700
  --> >= for both = LAPTOP
  --> < for width only = MOBILE VERTICAL
  --> < for height = MOBILE HORIZONTAL

  --> so for actual mobile, we're probably expecting 
  the longer dimension to be about 700-900px, which
  means that the MOBILE HORIZONTAL view should expect
  to deal with fairly small units of content at once
*/

const VERTICAL_BREAK = 600;
const HORIZONTAL_MIN = 450;
// const SMALL_HORIZONTAL_BREAK = 1050;
const MOBILE_HORIZONTAL_BREAK = 600;

// from: https://dev.to/nirazanbasnet/dont-use-calc(100 * var(--mvh))-for-mobile-responsive-3o97
const setDocumentHeightCSSVar = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--mvh', `${window.innerHeight/100}px`);

  document.documentElement.style.getPropertyValue("--mvh");
}

// run it initially
let runOnce = false;
let count = 0;
while(!runOnce && count < 1000){
  console.log("INITIAL SET CSS VAR");
  if(document.documentElement){
    setDocumentHeightCSSVar();
    runOnce = true;
    
    console.log("INITIAL HAS BEEN SET!", document.documentElement.style.getPropertyValue("--mvh"));
  }
  count++;
}

Vue.use(VueMq, {
  breakpoints: {
    mobile: 600,
    smallscreen: 1051,
    large: Infinity
  }
});

Vue.use(cloneDeep);

// store for the current window size
// meant to respond to screen size changes as well

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isMobile: null,
    isSmallScreen: null,
    isRotatedMobile: null,
    isBorderlineTablet: null,
    isMidTablet: null,
    isBorderlineLaptop: null,
    isSignMode: null,
    isVeryLargeScreen: null,
    isDisconnected: null,
    vehiclesLoaded: false,
    stopsLoaded: false,
    vehicleLocations: [],
    stopLocations: [],
    troubleshootingMode: false
  },
  getters: {
    vehicleLocations: state => {
      console.log("MEMBER STORE", state.vehicleLocations);
      return state.vehicleLocations
    },
    stopLocations: state => state.stopLocations,
    vehiclesLoaded: state => state.vehiclesLoaded,
    stopsLoaded: state => state.stopsLoaded,
    isMobile: state => state.isMobile,
    isSmallScreen: state => state.isSmallScreen,
    isRotatedMobile: state => state.isRotatedMobile,
    isBorderlineTablet: state => state.isBorderlineTablet,
    isMidTablet: state => state.isMidTablet,
    isBorderlineLaptop: state => state.isBorderlineLaptop,
    isSignMode: state => state.isSignMode,
    isVeryLargeScreen: state => state.isVeryLargeScreen,
    isDisconnected: state => state.isDisconnected,
    isTroubleshootingMode: state => state.troubleshootingMode
  },
  mutations: {
    setWindowSize(state) {
      state.windowWidth = window.innerWidth;
      if(state.windowHeight !== window.innerHeight){
        setDocumentHeightCSSVar();
        state.windowHeight = window.innerHeight;
      }
      console.log("SET THE WIDTH AND HEIGHT", state);
    },
    setVehiclesLoaded(state, val) {
      console.log("SET VEHICLES LOADED", val);
      state.vehiclesLoaded = val;
    },
    setStopsLoaded(state, val) {
      console.log("SET STOPS LOADED", val);
      state.stopsLoaded = val;
    },
    setVehicleLocations(state, list) {
      console.log("SETTING THE VEHICLE LOCATIONS STORE MEMBER", list);
      state.vehicleLocations = [...list];
      state.vehiclesLoaded = true;
    },
    setStopLocations(state, list) {
      console.log("SETTING THE STOP LOCATIONS STORE MEMBER", list);
      state.stopLocations = [...list];
      state.stopsLoaded;
    },
    setIsMobile(state, val){
      console.log("STATE SET MOBILE", val);
      state.isMobile = val;
      if(val) {
        state.isSmallScreen = true;
        state.isSignMode = false;
        state.isBorderlineTablet = false;
        state.isMidTablet = false;
        state.isBorderlineLaptop = false;

        if(store.state.windowHeight < VERTICAL_BREAK && store.state.windowWidth > HORIZONTAL_MIN){
          console.log("IS ROTATED 1", store.state.windowHeight, VERTICAL_BREAK, store.state.windowWidth, HORIZONTAL_MIN);
          state.isRotatedMobile = true;
        }
        else{
          console.log("IS ROTATED FALSE 1", store.state.windowHeight, VERTICAL_BREAK, store.state.windowWidth, HORIZONTAL_MIN);
          state.isRotatedMobile = false;
        }
      }
      else{
        console.log("ROTATED FALSE OUTER 1")
        state.isRotatedMobile = false;
      }
    },
    setIsSmallScreen(state, val){
      console.log("STATE SET SMALL SCREEN", val);
      state.isSmallScreen = val;
      if(!val){
        state.isMobile = false;
        state.isBorderlineTablet = false;
        state.isMidTablet = false;
      }
      else{
        state.isSignMode = false;
        state.isBorderlineLaptop = false;

        if(store.state.windowHeight < VERTICAL_BREAK && store.state.windowWidth > HORIZONTAL_MIN){
          console.log("IS ROTATED 2", store.state.windowHeight, VERTICAL_BREAK, store.state.windowWidth, HORIZONTAL_MIN);
          state.isRotatedMobile = true;
        }
        else{
          console.log("IS ROTATED FALSE 2", store.state.windowHeight, VERTICAL_BREAK, store.state.windowWidth, HORIZONTAL_MIN);
          state.isRotatedMobile = false;
        }
      }
    },
    setIsBorderlineTablet(state, val){
      if(store.state.windowHeight < VERTICAL_BREAK){
        // this is just rotated mobile mode
        state.isBorderlineTablet = false;
        state.isMidTablet = false;
      }
      else {
        state.isBorderlineTablet = val;
        if(val){
          state.isMidTablet = false;
        }
      }
    },
    setIsMidTablet(state, val){
      if(store.state.windowHeight < VERTICAL_BREAK){
        // this is just rotated mobile mode
        state.isBorderlineTablet = false;
        state.isMidTablet = false;
      }
      else {
        state.isMidTablet = val;
        if(val){
          state.isBorderlineTablet = false;
        }
      }
    },
    setIsBorderlineLaptop(state, val){
      const height = store.state.windowHeight;
      const width = store.state.windowWidth;

      console.log("BORDERLINE LAPTOP?", val, width, height, width/height, 11/7, width/height < 11/7);

      if(val && width/height < 11/7){
        state.isBorderlineLaptop = true;
      }
      else{
        state.isBorderlineLaptop = false;
      }
    },
    setSignMode(state, val){
      console.log("STATE SET SIGNAGE MODE", val);
      state.isSignMode = val;
      if(val){
        state.isMobile = false;
        state.isSmallScreen = false;
        state.isBorderlineTablet = false;
        this.isMidTablet = false;
        state.isBorderlineLaptop = false;
      }
    },
    setIsVeryLargeScreen(state, val){
      state.isVeryLargeScreen = val;
    },
    setIsDisconnected(state, val){
      console.log("STATE SET IS DISCONNECTED MODE", val);
      state.isDisconnected = val;
    },
    clearMapData(state) {
      state.vehicleLocations = [];
      state.stopLocations = [];
    },
    setTroubleshootingMode(state, val){
      state.troubleshootingMode = !!val;
    }
  }
});

Vue.mixin({
  methods: {
    screen_MOBILE_VERT() {
      if(store.state.windowWidth <= MOBILE_HORIZONTAL_BREAK && StorageEvent.windowHeight >= VERTICAL_BREAK){
        return true;
      }
      return false;
    },
    screen_MOBILE_HOR() {
      if(store.state.windowHeight < VERTICAL_BREAK){
        return true;
      }
      return false;
    },
    screen_LAPTOP() {
      console.log("CHECK", store, store.state);
      console.log("CHECK WIDTH", store.state.windowWidth, MOBILE_HORIZONTAL_BREAK);
      console.log("CHECK HEIGHT", store.state.windowHeight, VERTICAL_BREAK);
      if(store.state.windowWidth >= MOBILE_HORIZONTAL_BREAK && store.state.windowHeight >= VERTICAL_BREAK){
        return true;
      }
      return false;
    }
  }
});

Object.defineProperty(Vue.prototype, '$isMobile', {
  get () {
    return this.$store.getters.isMobile;
  }
});

Object.defineProperty(Vue.prototype, '$isSmallScreen', {
  get () {
    return this.$store.getters.isSmallScreen;
  }
});

Object.defineProperty(Vue.prototype, '$isRotatedMobile', {
  get () {
    return this.$store.getters.isRotatedMobile;
  }
});

Object.defineProperty(Vue.prototype, "$isBorderlineTablet", {
  get () {
    return this.$store.getters.isBorderlineTablet;
  }
});

Object.defineProperty(Vue.prototype, "$isMidTablet", {
  get () {
    return this.$store.getters.isMidTablet;
  }
});

Object.defineProperty(Vue.prototype, "$isBorderlineLaptop", {
  get () {
    return this.$store.getters.isBorderlineLaptop;
  }
});

Object.defineProperty(Vue.prototype, '$isSignMode', {
  get () {
    return this.$store.getters.isSignMode;
  }
});

Object.defineProperty(Vue.prototype, '$isVeryLargeScreen', {
  get () {
    return this.$store.getters.isVeryLargeScreen;
  }
});

Object.defineProperty(Vue.prototype, '$isDisconnected', {
  get () {
    return this.$store.getters.isDisconnected;
  }
});

Object.defineProperty(Vue.prototype, '$isTroubleshootingMode', {
  get () {
    return this.$store.getters.isTroubleshootingMode;
  }
});

Object.defineProperty(Vue.prototype, '$QR_HEIGHT', {
  get () {
    return 12;
  }
});


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
  watch: {
    $isMobile () {
      console.log('$isMobile watcher triggered');
    },
    $isSmallScreen () {
      console.log('$isSmallScreen watcher triggered');
    },
    $isRotatedMobile () {
      console.log("$isRotatedMobile watcher triggered");
    },
    $isBorderlineTablet () {
      console.log("$isBorderlineTablet was triggered");
    },
    $isMidTablet () {
      console.log("$isMidTablet was triggered");
    },
    $isBorderlineLaptop () {
      console.log("$isBorderlineLaptop was triggered");
    },
    $isSignMode () {
      console.log('$isSignMode watcher triggered');
    },
    $isDisconnected() {
      console.log("$isDisconnected watcher triggered");
    }
  }
}).$mount('#app');