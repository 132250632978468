
($mq === 'mobile' || query.layout === 'm') && query.layout !== 'l'

:style="{fontSize: appliedFontSize + 'vh'}"

<template>
    <div
        v-if="$isSmallScreen"
        class="home portrait mobile scroll-check" 
    >
        <NJT_Title />

        <BusCascadingSelections v-if="pageType === 'bus'" @navigate-to-trips="this.navigateToTrips" />
        <div v-if="pageType === 'bus'" class="divider" />
        <StopIDSelection v-if="pageType !== 'lightrail'" @navigate-to-trips="this.navigateToTrips" />
        <LightRailCascadingSelections v-else @navigate-to-trips="this.navigateToTrips" />

        <div class="sts-nav-button" v-if="pageType === 'rail'" @click="navigateTo_STS">
            <v-btn
                rounded
            >
                Station To Station
            </v-btn>
        </div>

        <div class="divider" />
        <NearbyContainer :usingGeolocation="usingGeolocation" :currentPosition="currentPosition" :showMapButton="true"
            :getOwnData="true"
            @navigate-to-stops = "navigateToStops"
            @navigate-to-trips = "navigateToTrips"
        />
        <div class="map-button-container" v-if="pageType !== 'lightrail'">
            <MapButton :withLabel="true" :usingGeolocation="usingGeolocation" :query="query" />
        </div>
    </div>
    <div v-else 
        :class="'home landscape ' + pageType"
    >
        <LaptopNavBar :condensed="false" :pageType="pageType" :query="query" />

        <!-- <div v-if="currentPosition && currentPosition.lat" style="color: red;">
            {{currentPosition.lat}}, {{currentPosition.lng}}
        </div> -->

        <div class="home-container">
            <div class="selections">
                <div :style="{
                        display:'flex', 
                        flexDirection:'column', 
                        //height:this.topLandscapeHeight, 
                        alignItems:'center',
                        //justifyContent: 'space-between',
                    }"
                >
                    <!-- <CascadingSelections v-if="pageType != 'rail'" @navigate-to-trips="this.navigateToTrips" /> -->
                    <StopIDSelection :showRoute="pageType === 'bus'" @navigate-to-trips="this.navigateToTrips" />
                                <!-- <div v-if="pageType != 'rail'" class="divider" />
                    <StopIDSelection v-if="pageType != 'rail'" @navigate-to-trips="this.navigateToTrips" /> -->
                </div>
            </div>
            <div class="nearby">
                <!-- <MapDisplay 
                    :usingGeolocation="usingGeolocation" 
                    :currentPosition="currentPosition" 
                    :height="topLandscapeHeight" 
                    :pageType="pageType" 
                /> -->
                <NearbyContainer :usingGeolocation="usingGeolocation" :currentPosition="currentPosition"
                    :getOwnData="true"
                    @navigate-to-stops = "navigateToStops"
                    @navigate-to-trips = "navigateToTrips"
                />
            </div>
            <div class="map-button-container" v-if="pageType !== 'lightrail'">
                <div class="sts-nav-button" v-if="pageType === 'rail'" @click="navigateTo_STS">
                    <v-btn
                        rounded
                    >
                        Station To Station
                    </v-btn>
                </div>
                <MapButton :withLabel="true" :usingGeolocation="usingGeolocation" :query="query" />
            </div>
        </div>
    </div>
</template>

<script>
    import NJT_Title from "@/components/NJT_Title";
    import NearbyContainer from "@/components/NearbyContainer";
    import BusCascadingSelections from "@/components/BusCascadingSelections";
    import LightRailCascadingSelections from "@/components/LightRailCascadingSelections";
    import StopIDSelection from "@/components/StopIDSelection";
    // import MapDisplay from "@/data_display/MapDisplay";
    import { setQueryParameters, onlyNonNullValues, determineDefaultFontSize, encryptTrip, decryptObject, minPositionDifference /*encryptObject*/ } from "@/Utilities";
    import LaptopNavBar from "../components/LaptopNavBar";

    import MapButton from "@/components/MapButton";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    export default {
        name: "Home",
        components: {
            NearbyContainer,
            BusCascadingSelections,
            LightRailCascadingSelections,
            StopIDSelection,
            // MapDisplay,
            NJT_Title,
            LaptopNavBar,
            MapButton        
        },
        data() {
            return {
                query: {
                    layout: "",
                    fontsize: "",
                    scrolling: "",
                    route: "",
                    direction: "",
                    stop: "",
                    station: "",
                    rows: "",
                    status: "",
                    late: null,
                    dark: null
                },
                appliedFontSize: null,
                usingGeolocation: false,
                geolocationDenied: false,
                currentPosition: null,
                topLandscapeHeight: "calc(60 * var(--mvh))",
                pageType: "bus"
            }
        },
        created() {
            // do we even care about these here?
            setQueryParameters(this, true);
            this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq, this.$isSmallScreen, "HOME");

            this.pageType = this.$route.path.split("/")[1];

            if(navigator.permissions){
                navigator.permissions.query({name:'geolocation'})
                .then((permissionStatus) => {
                    console.log('geolocation permission state is ', permissionStatus.state);

                    this.processGeolocationState(permissionStatus);

                    permissionStatus.onchange = () => {
                        console.log('geolocation permission state has changed to ', this.state);
                        this.processGeolocationState(permissionStatus);
                    };
                })
                .catch(err => alert("permission query error: " + err.message));
            }
            else{
                this.watchPosition();
            }
        },
        mounted() {
            const prefix = this.$route.path.split("/")[1];
            console.log("PREFIX", prefix);
            const lap = this.screen_LAPTOP();
            console.log("SCREEN LAPTOP", lap);

            this.geolocate();
        },
        destroyed() {
            console.log("DESTROY HOME");
            executeKillSwitch();
        },
        methods: {
            navigateTo_STS() {
                this.$router.push({
                    path: "station"
                });
            },
            navigateToTrips(stopValue, routeValue, directionValue, stopName, modeVal){

                console.log("NAVIGATE TO TRIPS MODE", {stopValue, routeValue, directionValue, stopName, modeVal});

                if(this.pageType === "rail"){
                    this.query.station = stopValue;
                }
                else{
                    this.query.stop = stopValue;
                    this.query.route = routeValue;
                    this.query.direction = directionValue;
                }

                if(stopName){
                    this.query.stopName = stopName;
                }

                if(modeVal === "NLR" || modeVal === "RL" || modeVal === "HBLR"){
                    this.query.route = modeVal;
                }

                console.log("NAVIGATE", this.query);

                this.$router.push({
                    path: `/${this.pageType}/trips`, 
                    query: onlyNonNullValues(this.$data.query)
                });
            },
            navigateToStops(trip, fromNearby) {

                console.log("INVALID MEMBER HOME NAVIGATE?", trip);

                let valueToUse = null;
                let vehicleID = null;

                if(this.pageType === "rail"){
                    valueToUse = trip.VehicleID;
                    this.$data.query.mode = "RAIL";
                    vehicleID = trip.VehicleID;
                }
                else {
                    valueToUse = encryptTrip(trip);
                    vehicleID = trip.busid;

                    console.log("ENCRYPTED TRIP VALUE TO USE", trip, valueToUse);

                    const o = decryptObject(valueToUse);

                    console.log("DECRYPTED TRIP IMMEDIATELY", o);

                    // const params = {
                    //     internal_trip_number: trip.internal_trip_number,
                    //     sched_dep_time: trip.sched_dep_time,
                    //     timing_point_id: trip.timing_point_id,
                    //     route: trip.public_route || trip.linecode,
                    //     // iconStyle: this.iconStyle
                    // };

                    // // the only way to pas all of the information necessary for getting
                    // // the stops and maintaining proper future navigation to the 
                    // // "all trips" route is to have all of the above passed through
                    // // - to make this part of the URL, this object will be encrypted into a string
                    // //   and passed along in the querystring
                    // valueToUse = encryptObject(params);
                }

                console.log("NAVIGATE PATH", `/${this.pageType}/stops`);

                this.query.vid = vehicleID;
                this.query.fromHomeNearby = true;

                if(this.pageType === "rail"){
                    this.query.station = " ";
                }
                else{
                    this.query.stop = " ";
                }

                if(this.pageType === "lightrail" && fromNearby){
                    this.query.fromNearby = true;
                }

                this.$router.push({
                    path: `/${this.pageType}/stops`, 
                    query: onlyNonNullValues({...this.$data.query, tripid: valueToUse})
                });
            },            
            geolocate: function() {
                navigator.geolocation.getCurrentPosition(position => {
                    console.log("WE GOT A POSITION", position);

                    if(position){
                        this.usingGeolocation = true;
                        this.currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                    else{
                        setTimeout(this.geolocate, 50);
                    }
                }, (err) => {
                    console.log("GEO LOCATING GET CURRENT ERROR", err);
                    if(err.code === 3){
                        this.geolocate();
                    }
                }, 
                {timeout: 1000, enableHighAccuracy: false, maximumAge:Infinity}
                );
            },
            watchPosition() {

                this.geolocate();

                navigator.geolocation.watchPosition((position) => {
                    this.usingGeolocation = true;

                    // check for some minimum distance change

                    let proceed = !this.currentPosition;
                    proceed |= minPositionDifference(position, this.currentPosition);

                    if(proceed){
                        this.currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                },
                function(error) {
                    if (error.code == error.PERMISSION_DENIED){
                        this.usingGeolocation = false;
                    }
                });
            },
            processGeolocationState(permissionStatus) {
                switch(permissionStatus.state) {
                    case "granted":
                        this.usingGeolocation = true;
                        this.geolocationDenied = false;

                        this.watchPosition();
                            // this.currentPosition.lat = 40.7357;
                            // this.currentPosition.lng = -74.1974;

                        // navigator.geolocation.getCurrentPosition(position => {
                        //     this.currentPosition = {
                        //         lat: position.coords.latitude,
                        //         lng: position.coords.longitude
                        //     };
                            
                        //     console.log("Current Position", this.currentPosition);
                        // });

                        // navigator.geolocation.watchPosition(p => {
                        //     const latDiff = Math.abs(p.coords.latitude - this.currentPosition.lat);
                        //     const lonDiff = Math.abs(p.coords.longitude - this.currentPosition.lng);

                        //     console.log("WATCH THE POSITION", p, this.currentPosition, latDiff, lonDiff);

                        //     const MIN_DIFF = 0.00002;

                        //     if(latDiff > MIN_DIFF || lonDiff > MIN_DIFF){
                        //         this.currentPosition = {
                        //             lat: p.coords.latitude,
                        //             lng: p.coords.longitude
                        //         };
                        //         console.log("CHANGE THE CURRENT POSITION", this.currentPosition);
                        //     }
                        // });

                        break;
                    case "denied":
                        this.usingGeolocation = false;
                        this.geolocationDenied = true;
                        break;
                    case "prompt":
                        this.watchPosition();
                        break;
                    default:
                        // treat as denied
                        this.usingGeolocation = false;
                }
            },
            toggleTroubleshooting() {
                let val = true;
                if(this.$isTroubleshootingMode){
                    val = false;
                }

                const allIndicators = document.querySelectorAll(".message-square");

                for(let i = 0; i < allIndicators.length; i++){
                    if(!val){
                        allIndicators[i].classList.add("message-hidden");
                    }
                    else{
                        allIndicators[i].classList.remove("message-hidden");
                    }
                }

                this.$store.commit("setTroubleshootingMode", val);
            }
        }
    }
</script>

<style scoped>
    .portrait, .selections {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-top: 2vh; */
    }

    .portrait {
        height: 100%;
    }

    .landscape {
        /* display: grid; */
        /* grid-template-columns: 3fr 20fr 14fr; */
        /* background-color: #fcfcfc; */
        position: relative;
        overflow: hidden;
    }

    .landscape .home-container {
        display: grid;
        grid-template-columns: 60vw 40vw;
        height: min(calc(85 * var(--mvh)), 85vh);
        width: 100%;
    }

    .landscape .selections {
        height: calc(60 * var(--mvh));
        display: grid;
        place-items: center;
    }

    /* .landscape .selections::after {
        content: "";
        position: absolute;
        top: 20vh;
        right: 45vw;
        height: 70vh;
        width: 1px;
        background-color: var(--primary-color);
        opacity: 0.1;
    } */

    .nearby {
        height: 100%;
        display: grid;
        grid-template-rows: 3fr 2fr;
        /* padding: 1vh 1vh 0 0; */
        /* margin-top: calc(3.5vh + 5.5rem + 1px); */
    }

    .landscape .nearby {
        display: block;
        grid-row: span 2;
    }

    .divider {
        background-color: #ddd;
        height: 1px;
        width: 90%;
        margin: 2vh auto;
    }

    .landscape .divider {
        margin: calc(1 * var(--mvh)) auto calc(3.5 * var(--mvh));
    }

    .mobile .divider {
        margin: calc(4 * var(--mvh)) auto calc(3 * var(--mvh));
    }

    .map-button-container {
        height: calc(25 * var(--mvh));
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile .map-button-container {
        height: initial;
        margin-bottom: calc(4 * var(--mvh));
    }

    /* @media only screen and (max-width: 600px) {
        .divider {
            margin: 4vh auto;
        }
    } */

    .troubleshooting-button {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding: 0.5rem;

        z-index: 1000;
        border: 1px solid black;
    }

    .mobile .troubleshooting-button {
        display: none;
    }
</style>

<style>
    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color: #ccc !important;
    }

    .v-slide-group__wrapper {
        border: 1px solid #ccc !important;
    }

    .v-tabs-slider {
        background-color: var(--primary-color);
    }

    input {
        background-color: white;
    }

    .theme--light.v-btn.v-btn--has-bg:not(.v-btn--disabled) {
        background-color: #e9e9e9 important!;
        max-width: 28vw;
    }

    .theme--dark.v-btn.v-btn--has-bg {
        background-color: var(--primary-color);
    }

    .v-btn__content {
        width: 100%;
    }

    .v-btn:not(.v-btn--disabled) {
        border: 1px solid var(--primary-color);
        min-height: 36px;
    }

    .footer .v-btn:not(.v-btn--disabled) {
        border: none;
    }

    .menuable__content__active {
        left: initial !important;
    }

    .mobile .sts-nav-button .v-btn {
        margin-top: 3rem;
        max-width: 50vw !important;
    }

    .landscape .sts-nav-button {
        margin-right: 3rem;
    }
</style>