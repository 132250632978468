
Element for selecting either a stopID or a terminal for then getting trip data

The stop will be a text field and the terminal will be a dropdown

Only one will be shown at a time, with the switch happening via tabs,
which are handled via the vuetify v-tabs component

<template>
    <div :class="'form' + ($isSmallScreen ? ' mobile' : '')">
        <div class="banner">{{
            "Find " + pageType + " Departures By: " + (pageType === "rail" ? "Station" 
            : (isRoute && !$isSmallScreen) ? "Route" : (isStop ? "Stop ID" : "Terminal"))
        }}</div>

        <!-- <v-tabs v-if="showTabs" grow v_model="active_tab">
            <v-tab :key="0" @click="setStopTab">Stop ID</v-tab>
            <v-tab :key="1" @click="setLocationTab">Terminal</v-tab>
        </v-tabs> -->

        <StopTabs v-if="showTabs && pageType === 'bus'" :showRoute="showRoute" :activeTab="active_tab" @clickTab="clickTab" />

        <div v-if="pageType === 'bus' && isRoute && showRoute">
            <BusCascadingSelections @navigate-to-trips="this.cascadeNavigateToTrips" :noTitle="true" />
        </div>
        <div v-else-if="pageType === 'lightrail'">
            <LightRailCascadingSelections @navigate-to-trips="this.cascadeNavigateToTrips" :noTitle="true" />
        </div>
        <div v-else-if="pageType === 'bus' && isStop">
            <input 
                type="text"
                class="input"
                autocomplete="off"
                id="stop_id" 
                name="stopValue" 
                placeholder="example: 19171"
                v-model="stopValue"
                @change="this.setStopValue"
            />
        </div>
        <div v-else class="extra-terminal-indicator">
            <DropDown 
                :key="lastSwitch"
                defaultValue="Select Terminal"
                :waiting="locations.length === 0"
                :value="locationValue"
                valueField="bus_terminal_code"
                displayField="bus_terminal_name"
                :items="locations"
                @onChange="this.setLocationValue"
            />
        </div>

        <v-btn
            v-if="pageType === 'rail' || (pageType === 'bus' &&  (active_tab !== 0 || !showRoute))"
            :disabled="(isStop && stopValue === '') || (!isStop && locationValue === '-1')" 
            rounded
            color="var(--primary-color)"
            :dark="!(isStop && stopValue === '') && !(!isStop && locationValue === '-1')"
            @click="this.navigateToTrips"
        >
            GET {{pageType}} {{ pageType.toLowerCase() === "bus" ? "DEPARTURES" : "ARRIVALS" }}
        </v-btn>
    </div>
</template>

<script>
    import { getLocations } from "@/requests/OptionRequests";
    import { setName } from "@/NameMappings";
    import DropDown from "@/components/DropDown";
    import StopTabs from "@/components/StopTabs";

    import BusCascadingSelections from "@/components/BusCascadingSelections.vue";
    import LightRailCascadingSelections from "@/components/LightRailCascadingSelections.vue";

    import InitialLocations from "@/InitialLocations";

    const BASE_STORAGE_KEY = "__-StopIDSelection-__";
    const JOINED_STORAGE_KEY = "__-JOINED_SELECTION-__";

    let VRef = null;

    export default {
        name: "StopIDSelection",
        props: ["showRoute"],
        components: {
            DropDown,
            StopTabs,
            BusCascadingSelections,
            LightRailCascadingSelections
        },
        data() {
            return {
                lastSwitch: "",
                stopValue: "",
                locationValue: "-1",
                locations: [],
                isRoute: false,
                isStop: false,
                isTerminal: true,
                noSelection: false,
                active_tab: 2,
                disabled: true,
                pageType: "bus",
                showTabs: true
            }
        },
        watch: {
            locations(newVal) {
                console.log("LOCATION CHANGED");
                const d = new Date();
                this.lastSwitch = d + "";

                const copy = [...newVal];

                const containerRef = document.querySelector(".extra-terminal-indicator");
                containerRef.style.backgroundColor = "#888";

                const str = JSON.stringify(copy);
                const hidden = document.createElement("div");
                hidden.innerText = str;
                hidden.style.width = "0px";
                hidden.style.height = "0px";
                hidden.style.overflow = "hidden";

                containerRef.appendChild(hidden);
            },
            lastSwitch(newVal) {
                console.log("SETTING LAST SWITCH HERE", this.lastSwitch, newVal, this.locations);
            }
        },
        created(){
            VRef = this;

            console.log("CURRENT URL", window.location.href, window.location.href.split("/"));

            const pathSplit = window.location.href.split("/");

            let locs = [];

            console.log("PATH SPLIT", pathSplit, InitialLocations);

            pathSplit.forEach(s => {
                if(s === "rail"){
                    console.log("RAIL INITIAL LOCATIONS", InitialLocations["rail"]);
                    locs = InitialLocations["rail"];
                }
                if(s === "bus"){
                    console.log("BUS INITIAL LOCATIONS", InitialLocations["bus"]);
                    locs = InitialLocations["bus"];
                }
            });

            console.log("INITIAL LOCATIONS", locs);
            this.locations = locs;

            this.runLocationsRequest();
        },
        mounted() {
            // this.runLocationsRequest();
        },
        methods: {
            emptyClick(data) {
                console.log("SETTING EMPTY CLICK", this.locations, data, VRef === this);
                const d = new Date();
                VRef.lastSwitch = d + "";

                if(data && VRef.locations.length === 0){
                    VRef.locations = data;
                    VRef.retrieveSelectionsFromStorage();
                }
            },
            setLocationData(data){
                console.log("GET STATION DATA YYY", data);

                const copy = [...data];

                console.log("SETTING LOCATIONS HERE 1", this.locations);

                copy.forEach(loc => {
                    setName(loc.bus_terminal_code, loc.bus_terminal_name, this.pageType + "-stop");
                });

                copy.forEach(loc => {
                    setName(loc.bus_terminal_code, loc.bus_terminal_name, this.pageType + "-stop");
                });

                console.log("SETTING LOCATIONS HERE 2", this.locations);

                // OK, always getting it here
                this.locations = copy;

                console.log("LOCATION COPY", copy);

                setTimeout(() => {
                    this.emptyClick(copy, this.pageType);
                }, 100);

                console.log("SETTING LOCATIONS HERE 3", this.locations, JSON.stringify(this.locations));

                this.retrieveSelectionsFromStorage();

                console.log("SETTING LOCATIONS HERE 4", this.locations, this.$data.locations);

                // if I just force an update, will it re-render this and the child dropdown?
                // does this disrupt anything?
                this.$forceUpdate();

                console.log("SETTING LOCATIONS HERE 5", this.locations, this.lastSwitch);

                const d = new Date();
                this.lastSwitch = d + "";

                console.log("SETTING LOCATIONS HERE 6", this.locations, this.lastSwitch, typeof this.lastSwitch);

                console.log("SETTING THIS?", this);

                const containerRef = document.querySelector(".extra-terminal-indicator");
                containerRef.style.backgroundColor = "#333";

                const str = JSON.stringify(copy);
                const hidden = document.createElement("div");
                hidden.innerText = str;
                hidden.style.width = "0px";
                hidden.style.height = "0px";
                hidden.style.overflow = "hidden";

                containerRef.appendChild(hidden);

                setTimeout(() => {
                    // create an html element and add it to the window as an indicator
                    const indicate = document.createElement("div");
                    
                    indicate.classList.add("message-square");
                    if(!this.$isTroubleshootingMode){
                        indicate.classList.add("message-hidden");
                    }

                    indicate.style.height = "80px";
                    indicate.style.width = "80px";
                    indicate.style.backgroundColor = "green";
                    indicate.style.zIndex = "10000";
                    indicate.style.position = "absolute";
                    indicate.style.top = 0;
                    indicate.style.left = 0;

                    indicate.style.color = "white";

                    let str = "";
                    if(data){
                        str = "data exists: ";
                        if(data.length){
                            str += " " + data.length;
                        }
                    }
                    else{
                        str = "null data";
                    }

                    indicate.textContent = str;

                    document.body.appendChild(indicate);
                }, 10);

            },
            runLocationsRequest() {
                const type = this.$route.path.split("/")[1];
                this.pageType = type;

                const params = {
                    mode: null
                };

                // console.log("INITIAL LOCATIONS", InitialLocations[type]);
                // this.location = InitialLocations[type];

                // "/bus", "/lightrail", "/riverline"
                switch(this.pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                    case "hblr":
                        params.mode = "HBLR";
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        this.isStop = false;
                        this.active_tab = 2;
                        this.showTabs = false;
                        break;
                }

                // get locations
                // OK, let's try a different approach
                // just always make the call to the API no matter what
                // and then try to find the selections after that

                let indicators = document.querySelectorAll(".message-square");
                if(indicators){
                    for(let i = 0; i < indicators.length; i++) {
                        indicators[i].remove();
                    }
                }
                indicators = null;

                const skip = false;
                if(skip){
                    return;
                }

                if(this.pageType !== "rail"){
                    const pt = this.pageType;
                    console.log("NOT RAIL", this.pageType);
                    setTimeout(() => {
                        console.log("CHECK STOP IF AGAIN!!!!!!", pt);
                        if(!this.locations || this.locations.length === 0){
                            console.log("RUNNING STOP ID SELECTION AGAIN!!!!!!");
                            this.runLocationsRequest();
                        }
                    }, 5000);
                }

                console.log("GET LOCATIONS CALL", params);

                getLocations(params)
                .then(({data}) => {
                    console.log("GET LOCATION DATA", data);
                    this.setLocationData(data);
                })
                .catch(err => () => {
                    console.log("STOP ID SELECTION GET LOCATION ERROR", err);
                    
                    const indicate = document.createElement("div");
                    
                    indicate.classList.add("message-square");
                    if(!this.$isTroubleshootingMode){
                        indicate.classList.add("message-hidden");
                    }

                    indicate.style.height = "80px";
                    indicate.style.width = "80px";
                    indicate.style.backgroundColor = "purple";
                    indicate.style.zIndex = "10000";
                    indicate.style.position = "absolute";
                    indicate.style.top = "80px";
                    indicate.style.left = 0;

                    indicate.style.color = "white";

                    indicate.textContent = err.message;

                    document.body.appendChild(indicate);
                });

                /*
                if(!this.retrieveSelectionsFromStorage()){
                    console.log("COULD NOT RETRIEVE FROM STORAGE");
                    getLocations(params)
                    .then(({data}) => {
                        console.log("GET STATION DATA YYY", data);
                        this.locations = data;

                        this.locations.forEach(loc => {
                            setName(loc.bus_terminal_code, loc.bus_terminal_name, this.pageType + "-stop");
                        });

                        // if I just force an update, will it re-render this and the child dropdown
                        this.$forceUpdate();
                    });
                }
                else{
                    console.log("DON'T GET STATION DATA FROM API");
                }

                console.log("STATIONS", this.locations);

                this.locations.forEach(loc => {
                    setName(loc.bus_terminal_code, loc.bus_terminal_name, this.pageType + "-stop");
                });

                console.log("STATIONS SAVED", localStorage);
                */
            },
            setStopValue(evt){
                console.log("SET STOP VALUE?", evt.target.value);
                this.stopValue = evt.target.value;
            },
            setLocationValue(val){
                console.log("SET LOCATION VALUE?", val);
                this.locationValue = val;
            },
            clickTab(tab_index){
                if(tab_index === 0){
                    this.setRouteTab();
                }
                else if(tab_index === 1){
                    this.setStopTab();
                }
                else if(tab_index === 2){
                    this.setLocationTab();
                }
            },
            setRouteTab() {
                this.active_tab = 0;
                this.isRoute = true;
                this.isStop = false;
                this.isTerminal = false;
            },
            setStopTab(){
                this.active_tab = 1;
                this.isRoute = false;
                this.isStop = true;
                this.isTerminal = false;
            },
            setLocationTab(){
                this.active_tab = 2;
                this.isRoute = false;
                this.isStop = false;
                this.isTerminal = true;
            },
            cascadeNavigateToTrips(...args) {
                this.$emit("navigate-to-trips", ...args);
            },
            navigateToTrips() {
                const val = this.isStop ? this.stopValue : this.locationValue;

                console.log("NAV VAL", val);

                if(val){
                    this.saveSelectionsToStorage();
                    this.$emit("navigate-to-trips", val);
                }
                else{
                    this.noSelection = true;
                }
            },
            saveSelectionsToStorage(){
                const str = JSON.stringify(this.$data);
                console.log("SAVE SELECTION", this.$data);
                localStorage.setItem(BASE_STORAGE_KEY + this.pageType, str);

                const strJ = JSON.stringify({
                    active_tab: this.$data.active_tab,
                    isRoute: this.$data.isRoute,
                    isStop: this.$data.isStop,
                    isTerminal: this.$data.isTerminal
                });

                localStorage.setItem(JOINED_STORAGE_KEY + this.pageType, strJ);
            },
            retrieveSelectionsFromStorage(){
                const str = localStorage.getItem(BASE_STORAGE_KEY + this.pageType);
                const strJ = localStorage.getItem(JOINED_STORAGE_KEY + this.pageType);

                console.log("GET STATIONS FROM STORAGE? SETTINGS", str, strJ);

                if(str){
                    const obj = JSON.parse(str);
                    if(obj){

                        console.log("STOP ID SELECTION OBJECT ON LOAD", obj);

                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            if(key !== "locations"){
                                this[key] = obj[key];
                            }
                        });

                        // if(obj.lastSwitch){
                        //     const last = new Date(obj.lastSwitch);
                        //     const now = Date.now();

                        //     const breakpoint = 1717184089250;

                        //     console.log("CHECK WHEN LAST SWITCHED FOR LOCATIONS", last, now, now-last, 1717184089250);

                        //     if((now - last) < (1000 * 60 * 60 * 24) || (this.pageType === "BUS" && obj.locations.length < 100)){
                        //         this.locations = obj.locations;
                        //     }
                        // }
                    }
                }

                console.log("JOINED RETRIEVE", strJ);

                if(strJ && !this.$isSmallScreen){
                    const obj = JSON.parse(strJ);

                    if(obj){

                        console.log("JOINED LOOP", obj, this.$data);

                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            console.log("JOINED VALUE SET", key, obj[key], this[key]);
                            this[key] = obj[key];
                        });
                    }
                }

                console.log("AFTER JOINED SET", this.$data);

                if(str && strJ) {
                    // if(this.active_tab == 1){
                    //     console.log("STOP ACTIVE TAB 1");
                    //     this.setStopTab();
                    // }
                    // else{
                    //     console.log("STOP NOT ACTIVE TAB 1", this.active_tab);
                    //     this.setLocationTab();
                    // }

                    return true;
                }

                return false;
            }
        }
    }
</script>

<style scoped>



    .flex {
        display: flex;
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 45vw; /*500px;*/
        margin-bottom: 10px;
    }

    .form > *:not(:first-child) {
        margin-top: 10px;
    }

    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .selected {
        background-color: #aaa;
        color: blue;
        border-color: #aaa;
    }

    input {
        width: 100%;
        border-width: 1px;
        height: 36px;
        margin: 0;
        padding: 8px;
        /* font-size: 0.5rem; */
        text-align: center;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: 0.0892857143em;
        display: block;
    }

    .input {
        border-style: solid;
    }

    .form.mobile {
        width: 500px;
        max-width: 90vw;
        margin-bottom: 0;
    }

    .mobile .tabs {
        display: grid;
        grid-template-areas: 
            "banner banner"
            "stopid terminal";
        gap: 2px;
        justify-content: stretch;
    }

    .mobile .banner {
        grid-area: banner;
    }

    .mobile .stopid {
        grid-area: stopid;
    }

    .mobile .terminal {
        grid-area: terminal;
    }

    /* @media only screen and (max-width: 600px) {
        .form {
            width: 350px;
        }

        .tabs {
            display: grid;
            grid-template-areas: 
                "banner banner"
                "stopid terminal";
            gap: 2px;
            justify-content: stretch;
        }

        .banner {
            grid-area: banner;
        }

        .stopid {
            grid-area: stopid;
        }

        .terminal {
            grid-area: terminal;
        }
    } */
</style>