<template>
    <div class="loader-container">
        <div class="wave-container">
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
        </div>
        <div v-if="isProcessing" class="text">PROCESSING</div>
        <div v-else class="text">LOADING</div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: ["isProcessing"]
    }
</script>

<style scoped>
/*
    https://blog.hubspot.com/website/css-loading-animation#:~:text=Loading%20animations%20are%20notifications%20that,data%20or%20content%20to%20load.
*/
    .loader-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: calc(2 * var(--mvh));
    }

    .wave-container {
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background: #000; */
    }
    .text {
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        color: black;
    }
    .wave {
        width: 2px;
        height: 100%;
        /* background: linear-gradient(45deg, var(--primary-color), #fff); */
        background: black;
        margin: 10px;
        animation: wave 1s linear infinite;
        border-radius: 20px;
    }
    .wave:nth-child(2) {
        animation-delay: 0.1s;
    }
    .wave:nth-child(3) {
        animation-delay: 0.2s;
    }
    .wave:nth-child(4) {
        animation-delay: 0.3s;
    }
    .wave:nth-child(5) {
        animation-delay: 0.4s;
    }
    .wave:nth-child(6) {
        animation-delay: 0.5s;
    }
    .wave:nth-child(7) {
        animation-delay: 0.6s;
    }
    .wave:nth-child(8) {
        animation-delay: 0.7s;
    }
    .wave:nth-child(9) {
        animation-delay: 0.8s;
    }
    .wave:nth-child(10) {
        animation-delay: 0.9s;
    }

    @keyframes wave {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
</style>