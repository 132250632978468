<template>
    <div class="tab-container">
        <div 
            v-if="showRoute"
            :class="`tab + ${activeTab === 0 ? ' selected' : ' not-selected'}`" 
            ref="route"
            @click="clickRouteTab" 
        >
            Route
        </div>
        <div 
            :class="`tab + ${activeTab === 1 ? ' selected' : ' not-selected'}`" 
            ref="stop"
            @click="clickStopTab" 
        >
            Stop Id
        </div>
        <div 
            :class="`tab + ${activeTab === 2 ? ' selected' : ' not-selected'}`" 
            ref="location"
            @click="clickTerminalTab"
        >
            Terminal
        </div>
    </div>
</template>

<script>
    export default {
        name: "StopTabs",
        props: ["activeTab", "showRoute"],
        methods: {
            clickRouteTab(evt) {
                if(this.$props.activeTab !== 0){
                    this.$emit("clickTab", 0);
                }

                const ref = this.$refs.route;
                this.clickEffect(ref, evt);
            },
            clickStopTab(evt) {
                if(this.$props.activeTab !== 1){
                    this.$emit("clickTab", 1);
                }

                const ref = this.$refs.stop;
                this.clickEffect(ref, evt);
            },
            clickTerminalTab(evt) {
                if(this.$props.activeTab !== 2){
                    this.$emit("clickTab", 2);
                }

                const ref = this.$refs.location;
                this.clickEffect(ref, evt);
            },
            clickEffect(ref, evt){
                const circle = document.createElement("div");
                circle.classList.add("circle");

                const rect = ref.getBoundingClientRect();

                circle.style.left = evt.clientX - rect.left + "px";
                circle.style.top = evt.clientY - rect.top + "px";

                // document.appendChild(circle);
                ref.appendChild(circle);

                setTimeout(() => {
                    circle.remove();
                }, 1000);
            }
        }
    }
</script>

<style scoped>
    .tab-container {
        margin-top: calc(1.7 * var(--mvh));
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border: 1px solid #ccc;
        height: 48px;
        background: white;
    }

    .mobile .tab-container {
        grid-template-columns: 1fr 1fr;
    }

    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-transform: uppercase;
        /* font-size: 0.875rem; */
        font-size: 1.1rem;
        letter-spacing: 0.0892857143em;
        min-width: 90px;
        outline: none;
        padding: 0 calc(2 * var(--mvh));
        overflow: hidden;
        position: relative;
        text-align: center;
        transition-duration: 0.28s;
        transition-property: color, box-shadow, transform, opacity, background-color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;

        border-right: 1px solid #ccc;
    }



    .tab:last-of-type {
        border: none;
    }

    .not-selected {
        color: rgba(0, 0, 0, 0.54);
        /* background: linear-gradient(90deg, white, #f9f9f9); */
        /* border-right: 1px solid black; */
    }

    .not-selected:hover {
        color: var(--primary-color);
        background-color: #f9f9f9;
    }

    .selected:hover {
        background-color: #e9e9e9
    }

    .selected {
        color: inherit;
        font-weight: 500;
    }

    .selected::before {
        /* content: "*"; */
    }

    .selected::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: calc(0.3 * var(--mvh));
        width: 100%;
        background-color: var(--primary-color);
    }
</style>