
<svg height="2.5em" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <path d="M 250.845 417.506 C 387.265 417.506 497.829 327.471 497.829 216.355 C 497.829 105.238 387.265 15.203 250.845 15.203 C 114.425 15.203 3.861 105.238 3.861 216.355 C 3.861 259.97 20.937 300.297 49.88 333.274 C 48.047 356.968 38.882 378.05 29.234 394.103 C 23.926 403.001 18.525 410.157 14.57 414.992 C 12.543 417.409 10.999 419.247 9.842 420.504 C 9.262 421.084 8.877 421.568 8.587 421.858 L 8.298 422.148 C 3.861 426.597 2.606 433.172 5.018 438.975 C 7.43 444.777 13.027 448.549 19.297 448.549 C 46.986 448.549 74.868 439.943 98.023 429.885 C 120.116 420.214 138.93 408.706 150.41 400.293 C 181.09 411.414 215.051 417.603 250.845 417.603 L 250.845 417.506 Z M 127.352 185.408 C 151.119 185.408 165.973 211.196 154.09 231.828 C 148.575 241.403 138.384 247.301 127.352 247.301 C 103.587 247.301 88.733 221.512 100.618 200.882 C 106.132 191.307 116.323 185.408 127.352 185.408 Z M 250.845 185.408 C 274.611 185.408 289.466 211.196 277.581 231.828 C 272.068 241.403 261.875 247.301 250.845 247.301 C 227.079 247.301 212.225 221.512 224.109 200.882 C 229.623 191.307 239.815 185.408 250.845 185.408 Z M 343.464 216.355 C 343.464 192.532 369.191 177.643 389.772 189.554 C 399.326 195.082 405.21 205.298 405.21 216.355 C 405.21 240.176 379.483 255.066 358.9 243.156 C 349.349 237.626 343.464 227.411 343.464 216.355 Z" style=""/>
    <ellipse style="stroke: rgb(0, 0, 0); fill: rgb(255, 255, 255);" cx="127.231" cy="215.937" rx="31.968" ry="31.968"/>
    <ellipse style="stroke: rgb(0, 0, 0); fill: rgb(255, 255, 255);" cx="252.641" cy="215.938" rx="31.968" ry="31.968"/>
    <ellipse style="stroke: rgb(0, 0, 0); fill: rgb(255, 255, 255); transform-origin: 378.052px 216.758px;" cx="375.593" cy="215.938" rx="31.968" ry="31.968"/>
</svg>

<template>
    <div :class="'main-chat' + (mobile ? ' mobile' : '')">
        <div :class="chatOpen ? 'chat-open' : 'chat-closed'" @click="openChat">
            <ChatButton />
        </div>
        <div v-if="!isRotated" :class="'form ' + (chatOpen ? 'form-open' : (hasBeenOpened ? 'chat-closing' : 'form-closed'))">
            <div class="form-cover"></div>
            <div :class="'robot' + (textChanged ? ' text-changed' : '') + (processing ? ' processing' : '') + (processed ? ' processed' : '')">
                <div class="robot-antenna left">
                    <div class="antenna-shaft"></div>
                    <div class="antenna-bulb" ref="antenna1">
                    </div>
                </div>
                <div class="robot-antenna right">
                    <div class="antenna-shaft"></div>
                    <div class="antenna-bulb" ref="antenna2">
                    </div>
                </div>
                <div class="robot-wheel left"></div>
                <div class="robot-wheel right"></div>
                <div class="robot-hand left"></div>
                <div class="robot-hand right"></div>
                <div class="robot-head">
                    <div class="robot-face">
                        <div class="eyes left"></div>
                        <div class="eyes right"></div>
                        <div class="mouth"></div>
                    </div>
                </div>
            </div>
            <button class="close-button" @click="closeChat">X</button>
            <div id="chat-text-container" style="position: relative" :class="text ? '': 'empty'">
                <textarea id="chat-text" :class="text ? '': 'empty'" v-if="!processed" v-model="text">
                </textarea>
            </div>
            <button v-if="!processed && !processing" :class="'ask-bot-button' + ((text.length > 0) ? '': ' inactive')" @click="submitQuery">Ask Transit Bot</button>
            <button v-if="!processed && processing" :class="'ask-bot-button'">Thinking...</button>
            <div v-if="processed && responseText" class="bot-response">
                <div
                    v-for="(text, index) in responseText"
                    :key="index"
                >
                    {{ text }}
                </div>
                <br />
                <a v-if="from && to" :href="`/#/rail/Station?GetTripFrom=${from}&To=${to}`" @click="closeChat">Station to Station</a>
            </div>
            <div v-else-if="processed" class="bot-response">
                Hmm, I can't seem to find anything
            </div>
            <button v-if="processed" class="ask-bot-button" @click="resetForm">Another Question?</button>
        </div>
        <div v-else :class="'is-rotated form ' + (chatOpen ? 'form-open' : (hasBeenOpened ? 'chat-closing' : 'form-closed'))">
            <div class="form-cover"></div>
            <div :class="'robot' + (textChanged ? ' text-changed' : '') + (processing ? ' processing' : '') + (processed ? ' processed' : '')">
                <div class="robot-antenna left">
                    <div class="antenna-shaft"></div>
                    <div class="antenna-bulb" ref="antenna1">
                    </div>
                </div>
                <div class="robot-antenna right">
                    <div class="antenna-shaft"></div>
                    <div class="antenna-bulb" ref="antenna2">
                    </div>
                </div>
                <div class="robot-wheel left"></div>
                <div class="robot-wheel right"></div>
                <div class="robot-hand left"></div>
                <div class="robot-hand right"></div>
                <div class="robot-head">
                    <div class="robot-face">
                        <div class="eyes left"></div>
                        <div class="eyes right"></div>
                        <div class="mouth"></div>
                    </div>
                </div>
            </div>
            <div class="rotated-form">
                <button class="close-button" @click="closeChat">X</button>
                <div id="chat-text-container" style="position: relative;" :class="text ? '': 'empty'">
                    <textarea id="chat-text" v-if="!processed" v-model="text"></textarea>
                </div>
                <button v-if="!processed && !processing" :class="'ask-bot-button' + ((text.length > 0) ? '': ' inactive')" @click="submitQuery">Ask Transit Bot</button>
                <button v-if="!processed && processing" :class="'ask-bot-button'">Thinking...</button>
                <div v-if="processed && responseText" class="bot-response">
                    <div
                        v-for="(text, index) in responseText"
                        :key="index"
                    >
                        {{ text }}
                    </div>
                    <br />
                    <a v-if="from && to" :href="`/#/rail/Station?GetTripFrom=${from}&To=${to}`" @click="closeChat">Station to Station</a>
                </div>
                <button v-if="processed" class="ask-bot-button" @click="resetForm">Another Question?</button>
            </div>
        </div>
    </div>
</template>


<script>
    import { connectionCheck, runQuery } from "@/requests/ChatBotRequests";
    import ChatButton from "@/components/ChatButton";

    console.log("CHAT", connectionCheck, runQuery);

    let binaryInterval = null;

    const chatIDKey = "DV_CHAT_CLIENT_ID";

    export default {
        name: "Chat",
        props: ["mobile", "isRotated"],
        components: {
            ChatButton
        },
        data: () => ({
            chatOpen: false,
            hasBeenOpened: false,
            text: "",
            textChanged: false,
            responseText: null,
            processing: false,
            processed: false,
            from: null,
            to: null
        }),
        watch: {
            text(newVal){
                console.log("TEXT CHANGED!");
                if(newVal){
                    this.textChanged = true;
                }
                else{
                    this.textChanged = false;
                }
            }
        },
        methods: {
            createClientID() {
                const id = (Math.random() + "_" + Math.random()).replace(".", "_");
                const now = Date.now();

                const obj = {
                    id,
                    created: now
                };
                
                const str = JSON.stringify(obj);

                localStorage.setItem(chatIDKey, str);

                return id;
            },
            getClientID() {
                const currentClientID = localStorage.getItem(chatIDKey);
                if(currentClientID) {
                    const obj = JSON.parse(currentClientID);
                    if(obj && obj.created){
                        // const d = new Date(obj.created);
                        // const now = Date.now();

                        return obj.id;

                        //
                        // do I need to have this check?
                        // if it's a rolling window, then this would all have to be handled on the backend
                        //

                        // // include a little leeway
                        // const hours24 = 1000 * 60 * 60 * 23.5;

                        // if((now - d) >= hours24) {
                        //     // expired
                        //     return this.createClientID();
                        // }
                        // else {
                        //     return obj.id;
                        // }
                    }
                    else{
                        // incorrect JSON
                        return this.createClientID();
                    }
                }
                else{
                    // no object yet stored
                    return this.createClientID();
                }
            },
            openChat() {
                console.log("CHAT OPEN");
                this.hasBeenOpened = true;
                this.chatOpen = true;
            },
            closeChat() {
                console.log("CHAT CLOSE");
                this.chatOpen = false;
                // this.hasBeenOpened = false;
                this.text = "";
                this.textChanged = false;
                this.responseText = "";
                this.from = null;
                this.to = null;
                this.processing = false;
                this.processed = false;
            },
            submitQuery() {
                if(this.text.length === 0){
                    return;
                }

                this.processing = true;
                this.textChanged = false;

                this.createBinaryAnimation();

                const isTest = false;

                const client_id = this.getClientID();

                if(isTest){
                    setTimeout(() => {
                        this.processing = false;
                        this.processed = true;
    
                        if(binaryInterval){
                            clearInterval(binaryInterval);
                        }
    
                        this.responseText = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."];
                        this.from = "NY";
                        this.to = "AZ";
                        console.log("MOCK REQUEST COMPLETED");
                    }, 5000);
                }
                else{
                    runQuery(this.text, client_id)
                    .then(res => {

                        console.log("CHAT QUERY RETURN", res, res.data);
                    
                        this.processing = false;
                        this.processed = true;
    
                        if(binaryInterval){
                            clearInterval(binaryInterval);
                        }

                        const t = res.data.response.split("\n");

                        console.log("RESPONSE SPLIT", res.data.response, t);

                        this.responseText = t;
                        this.from = res.data.from;
                        this.to = res.data.to;
                    })
                    .catch(err => {
                        this.processing = false;
                        this.processed = true;
    
                        if(binaryInterval){
                            clearInterval(binaryInterval);
                        }

                        // need to check for the 429 error
                        if(err.response.status == "429"){
                            alert("You've hit your daily chatbot request limit! Please try again in a few hours.");
                        }
    
                        console.err("CHATBOT ERROR", err);
                    });
                }
            },
            resetForm() {
                this.processed = false;
                this.responseText = null;
                this.from = null;
                this.to = null;
            },
            createBinaryAnimation(){
                // <div class="binary" style="--angle:110deg; --dist:-50px;">1</div>

                let ref1 = this.$refs["antenna1"];
                let ref2 = this.$refs["antenna2"];

                console.log("DO WE HAVE REFS?", ref1, ref2, this.$refs);

                const addNewElement = (angle, dist, value, ref) => {
                    const d = document.createElement("div");
                    d.classList.add("binary");
                    d.style = `--angle:${angle}; --dist:${dist};`;
                    d.textContent = value;
                    ref.appendChild(d);

                    setTimeout(() => {
                        d.remove();
                    }, 1500);
                }

                binaryInterval = setInterval(() => {
                    const angle1 = Math.floor((Math.random() * 360)) + "deg";
                    const angle2= Math.floor((Math.random() * 360)) + "deg";
                    const dist1 = Math.floor((Math.random() * 30) + 40) + "px";
                    const dist2 = Math.floor((Math.random() * 30) + 40) + "px";
                    const value1 = (Math.random() > 0.5) ? "1" : "0";
                    const value2 = (Math.random() > 0.5) ? "1" : "0";
                    addNewElement(angle1, dist1, value1, ref1);
                    addNewElement(angle2, dist2, value2, ref2);
                }, 50);
            }
        }
    };
</script>

<style>
    /*
        BINARY ANIMATION
    */

    #chat-text-container {
        position: relative;
    }

    #chat-text-container.empty::before {
        position: absolute;
        content: "How do I get from Newark Penn to Trenton?";
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.7;
        width: 100%;
        font-size: 1.1rem;
    }

    #chat-text-container.empty::after {
        position: absolute;
        content: "Can I take train 1234 to Hoboken? When does train 1234 reach Hoboken?";
        top: calc(10% + 4.4rem);
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.7;
        width: 100%;
        font-size: 1.1rem;
    }

    .binary {
        position: absolute;
        color: black;
        font-size: 1.3rem;
        animation: fall-inward 1s forwards;
        z-index: 120;
        opacity: 0;

        transform-origin: center;
    }

    .mobile .binary {
        font-size: 1.6rem;
    }

    @keyframes fall-inward {
        0% {
            transform: rotate(var(--angle)) translateY(var(--dist));
            opacity: 1;
        }
        100% {
            transform: rotate(var(--angle)) translateY(0);
            opacity: 0;
        }
    }
</style>

<style scoped>
    .main-chat {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;

        --duration: 0.4s;
        --closing-duration: 0.6s;
        --robot-duration: 0.6s;
    }

    .form {
        position: absolute;
        transform: translate(-50%, -50%);
        overflow: hidden;
        border: 1px solid #ccc;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .form.is-rotated {
        display: grid;
        grid-template-columns: 3fr 4fr;
        align-content: center;
        justify-content: center;
    }

    .form-closed {
        top: calc(90vh + 17px);
        left: calc(90vw + 30px);
        z-index: 90;
        background: black;

        height: 34px;
        width: 60px;
        border-radius: 50%;
    }

    .mobile .form-closed {
        position: fixed;
        top: initial;
        bottom: calc(10vh - 29px);
        left: calc(90vw - 5px);
    }

    .chat-closed {
        position: absolute;
        top: calc(90vh);
        left: calc(90vw);
        z-index:100;
        animation: chat-closing var(--closing-duration) forwards;
    }

    .mobile .chat-closed {
        position: fixed;
        top: initial;
        bottom: calc(10vh + 22px);
        left: calc(90vw - 35px);
    }

    @keyframes chat-closing {
        0% {
            opacity: 0;
        }
        90% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .chat-open {
        position: absolute;
        top: 90vh;
        left: 90vw;
        z-index:100;
        animation: chat-opening var(--duration) forwards;
    }

    .mobile .chat-open {
        position: absolute;
        display: none;
    }

    @keyframes chat-opening {
        0% {
            opacity: 1;
        }
        20% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    .form-open {
        padding: 10px 40px;
        z-index: 100;
        box-shadow: 0 0 5vw rgba(0,0,0,0.5);
        animation: opening var(--duration) forwards;
    }

    .mobile .form-open {
        animation: opening-mobile var(--duration) forwards;
    }

    .is-rotated.form-open {
        animation: opening-rotated var(--duration) forwards;
    }

    @keyframes opening {
        0% {
            opacity: 0;
            top: calc(90vh + 17px);
            left: calc(90vw + 30px);
            z-index: 90;
            height: 34px;
            width: 60px;
            /* height: 1.5rem;
            width: 2rem; */
            border-radius: 50%;
            background: black;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            top: 50vh;
            left: calc(82vw - 100%);
            z-index: 100;
            height: 75vh;
            width: 320px;
            border-radius: 1rem;
            background: white;
        }
    }

    @keyframes opening-mobile {
        0% {
            opacity: 0;
            /* top: calc(90vh);
            left: calc(85vw); */
            bottom: calc(10vh);
            left: calc(90vw);
            left: 75vw;
            z-index: 90;
            height: 34px;
            width: 60px;
            /* height: 1.5rem;
            width: 2rem; */
            border-radius: 50%;
            background: black;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            top: 50vh;
            left: 50vw;
            z-index: 100;
            height: 75vh;
            width: 320px;
            border-radius: 1rem;
            background: white;
        }
    }

    @keyframes opening-rotated {
        0% {
            opacity: 0;
            /* top: calc(90vh);
            left: calc(85vw); */
            bottom: calc(10vh);
            left: calc(90vw);
            z-index: 90;
            height: 34px;
            width: 60px;
            /* height: 1.5rem;
            width: 2rem; */
            border-radius: 50%;
            background: black;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            top: 50vh;
            left: 50vw;
            z-index: 100;
            height: 250px;
            width: 550px;
            border-radius: 1rem;
            background: white;
        }
    }

    .chat-closing {
        animation: closing var(--closing-duration) forwards;
    }

    .mobile .chat-closing {
        animation: mobile-closing var(--closing-duration) forwards;
    }

    .is-rotated.chat-closing {
        animation: rotated-closing var(--closing-duration) forwards;
    }

    @keyframes closing {
        0% {
            opacity: 1;
            top: 50vh;
            left: calc(82vw - 100%);
            z-index: 100;
            height: 75vh;
            width: 320px;
            border-radius: 1rem;
            background: white;
            box-shadow: 0 0 5vw rgba(0,0,0,0.5);
            padding: 10px 40px;
        }
        50% {
            opacity: 1;
            top: 50vh;
            left: calc(82vw - 100%);
            z-index: 100;
            height: 75vh;
            width: 320px;
            border-radius: 1rem;
            background: white;
            padding: 10px 40px;
        }
        80% {
            opacity :1;
        }
        100% {
            opacity: 0;
            top: calc(90vh + 17px);
            left: calc(90vw + 30px);
            z-index: 90;
            height: 34px;
            width: 60px;
            /* height: 1.5rem;
            width: 2rem; */
            border-radius: 50%;
            background: black;
        }
    }

    @keyframes mobile-closing {
        0% {
            opacity: 1;
            top: 50vh;
            left: 50vw;
            z-index: 100;
            height: 75vh;
            width: 320px;
            border-radius: 1rem;
            background: white;
            box-shadow: 0 0 5vw rgba(0,0,0,0.5);
            padding: 10px 40px;
        }
        50% {
            opacity: 1;
            top: 50vh;
            left: 50vw;
            z-index: 100;
            height: 75vh;
            width: 320px;
            border-radius: 1rem;
            background: white;
            padding: 10px 40px;
        }
        80% {
            opacity :1;
        }
        100% {
            opacity: 0;
            bottom: calc(10vh);
            left: calc(90vw);
            z-index: 90;
            height: 34px;
            width: 60px;
            /* height: 1.5rem;
            width: 2rem; */
            border-radius: 50%;
            background: black;
        }
    }

    @keyframes rotated-closing {
        0% {
            opacity: 1;
            top: 50vh;
            left: 50vw;
            z-index: 100;
            height: 250px;
            width: 550px;
            border-radius: 1rem;
            background: white;
            box-shadow: 0 0 5vw rgba(0,0,0,0.5);
            padding: 10px 40px;
        }
        50% {
            opacity: 1;
            top: 50vh;
            left: 50vw;
            z-index: 100;
            height: 250px;
            width: 550px;
            border-radius: 1rem;
            background: white;
            padding: 10px 40px;
        }
        80% {
            opacity :1;
        }
        100% {
            opacity: 0;
            bottom: calc(10vh);
            left: calc(90vw);
            z-index: 90;
            height: 34px;
            width: 60px;
            /* height: 1.5rem;
            width: 2rem; */
            border-radius: 50%;
            background: black;
        }
    }

    .form-cover {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 110;
        pointer-events: none;
        background-color: black;
        opacity: 0;
    }

    .form-open .form-cover {
        animation: cover-hide var(--duration) forwards;
    }

    @keyframes cover-hide {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    .chat-closing .form-cover {
        animation: cover-show var(--duration) forwards;
    }

    @keyframes cover-show {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    /*
        FORM LAYOUT ITSELF
    */

    textarea {
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        border: 2px solid black;
        width: 120%;
        height: 11rem;
        margin-bottom: 10px;
        transform: translateX(-10%);
    }

    .is-rotated textarea {
        width: 100%;
    }

    .bot-response {
        padding: 0.75rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        /* border: 2px solid black; */
        width: 120%;
        height: 11rem;
        background-color: #f6f6f6;
        overflow-y: auto;
        margin-bottom: 10px;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .bot-response a {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0.5rem 0;
        text-align: center;
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        background: black;
        color: white;
        border-bottom-left-radius: 1rem;
        padding: 0.5rem 0.75rem;
        font-weight: bold;
    }

    .mobile .close-button {
        top: initial;
        bottom: 0;
        right: 0;
        border-bottom-left-radius: initial;
        border-top-left-radius: 1rem;
    }

    .ask-bot-button {
        color: white;
        background-color: black;
        padding: 0.3rem 0.55rem;
        font-size: 1.4rem;
        border-radius: 1.5rem;
        width: 100%;
    }

    .ask-bot-button.inactive {
        background-color: #666;
        cursor: default;
    }

    .mobile .ask-bot-button {
        width: 80%;
    }

    /*
        ROBOT
    */

    .robot {
        /* height: 600px; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        transform: scale(0.75) translateY(20px);
        margin-top: 10px;
        margin-bottom: 35px;

        --background: white;
        --outline-color: #14415a;
        --tongue-color: #e9b4b4;
        --inner-bg: #cedbdd;
    }

    .is-rotated .robot {
        transform: scale(0.75) translateY(20px) translateX(-20px);
    }

    .robot-head {
        height: 180px;
        width: 230px;
        position: relative;
        /* left: 50%;
        transform: translateX(-50%);
        top: -20px; */
        border: 5px solid var(--outline-color);
        border-radius: 75px/ 60px;
        background: var(--background);
        z-index: 4;
    }

    .robot-antenna {
        position: absolute;
        /* top: -20px; */
        top: 25px;
    }

    .robot-antenna.left {
        left: 25%;
    }

    .robot-antenna.right {
        right: 25%;
    }

    .robot-antenna .antenna-shaft {
        position: absolute;
        height: 25px;
        width: 6px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--outline-color);
    }

    .robot-antenna .antenna-bulb {
        position: absolute;
        top: -23px;
        left: 50%;
        transform: translateX(-50%);
        height: 25px;
        width: 25px;
        background: var(--inner-bg);
        border: 5px solid var(--outline-color);
        border-radius: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .robot-face {
        height: 120px;
        width: 160px;
        background: var(--inner-bg);
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
        border: 4px solid var(--outline-color);
        transition: all 200ms;
        border-radius: 55px;
    }

    .text-changed .robot-face {
        top: 35px;
    }

    .is-rotated .text-changed .robot-face {
        top: 25px;
        left: calc(50% + 5px);
    }

    .eyes {
        height: 4px;
        width: 20px;
        background: var(--outline-color);
        border-radius: 100%;
        position: absolute;
        transition: all 200ms;
        top: 45px;
    }

    .form-open .eyes {
        animation: eyes-open var(--robot-duration) forwards;
    }

    .chat-closing .eyes {
        animation: eyes-close var(--robot-duration) forwards;
    }

    @keyframes eyes-open {
        0% {
            height: 4px;
            top: 45px
        }
        50% {
            height: 4px;
            top: 45px;
        }
        100% {
            height: 20px;
            top: 30px;
        }
    }

    @keyframes eyes-close {
        0% {
            height: 20px;
            top: 30px;
        }
        60% {
            height: 4px;
            top: 45px;
        }
        100% {
            height: 4px;
            top: 45px;
        }
    }

    .text-changed .eyes {
        top: 45px !important;
    }

    .is-rotated .text-changed .eyes {
        top: 30px !important;
        transform: translateX(5px);
    }

    .eyes.left {
        left: 30px;
    }

    .eyes.right {
        right: 30px;
    }

    .mouth {
        height: 45px;
        width: 50px;
        border-radius: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scale(0.2);
        top: 55px;
        overflow: hidden;

        background: var(--outline-color);
        border: none;
        clip-path: polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%);
    }

    .form-open .mouth {
        animation: mouth-open var(--robot-duration) forwards;
    }

    .chat-closing .mouth {
        animation: mouth-close var(--robot-duration) forwards;
    }

    @keyframes mouth-open {
        0% {
            transform: translateX(-50%) scale(0.5);
        }
        100% {
            transform: translateX(-50%) scale(0.8);
        }
    }

    @keyframes mouth-close {
        0% {
            transform: translateX(-50%) scale(0.8);
        }
        100% {
            transform: translateX(-50%) scale(0.5);
        }
    }

    .robot .mouth::after {
        content: "";
        position: absolute;
        height: 80%;
        width: 80%;
        bottom: -30%;
        left: 50%;
        transform: translateX(-50%);
        background: var(--tongue-color);
        border-radius: 100%;
    }

    .text-changed .mouth {
        background: initial;
        border: 8px solid transparent;
        border-bottom-color: var(--outline-color);
        clip-path: none;
        top: 55px;
    }

    .text-changed .mouth::after {
        display: none;
    }

    .robot-wheel {
        position: absolute;
        height: 80px;
        width: 80px;
        border-radius: 100%;
        background: var(--background);
        border: 4px solid var(--outline-color);
        z-index: 3;
        /* top: 135px; */
        top: 20px;
    }

    .robot-wheel.left {
        left: 10px;
    }

    .robot-wheel.right {
        right: 10px;
    }

    .robot-wheel::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: var(--outline-color);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .robot-wheel::before {
        content: "";
        position: absolute;
        width: 78px;
        height: 4px;
        background-color: var(--outline-color);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(40deg);
    }

    .robot-hand {
        position: absolute;
        height: 35px;
        width: 35px;
        border-radius: 100%;
        background: var(--background);
        border: 4px solid var(--outline-color);
        z-index: 3;
        top: 60px;
        transition: 0.3s;
    }

    .robot-hand.left {
        /* left: -14px; */
        left: 10px;
    }

    .robot-hand.right {
        /* right: -14px; */
        right: 10px;
    }

    .text-changed .robot-hand {
        top: 50px;
    }

    .processing .robot-face {
        top: 20px !important;
    }

    .processing .eyes {
        height: 20px;
        top: 20px !important;
        animation: eye-slide 4s infinite;
    }

    @keyframes eye-slide {
        0% {
            transform: translateX(0);
        }
        35% {
            transform: translateX(5px);
        }
        65% {
            transform: translateX(-5px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .robot.processing .mouth {
        top: 60px;
        border: initial;
        border-bottom-color: initial;
        border-radius: 50%;
        background-color: var(--outline-color);
        height: 30px;
        width: 30px;
        clip-path: initial;
    }

    .processing .mouth:after {
        content: "";
        position: absolute;
        height: 80%;
        width: 80%;
        bottom: -30%;
        left: 50%;
        transform: translateX(-50%);
        background: var(--tongue-color);
        border-radius: 100%;
    }

    .processing .robot-wheel::before {
        animation: turn-wheel 3s infinite;
    }

    @keyframes turn-wheel {
        0% {
            transform: translate(-50%, -50%) rotate(40deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(400deg);
        }
    }

    .processing .robot-hand {
        top: 75px;
    }

    /*
        SPECIAL ROBOT ANIMATIONS

        arms, antenna, wheels
    */

    .form-open .robot-wheel {
        animation: wheel-open var(--robot-duration) forwards;
        animation-delay: var(--duration);
    }

    .chat-closing .robot-wheel {
        animation: wheel-close var(--robot-duration) forwards;
    }

    @keyframes wheel-open {
        0% {
            top: 20px;
        }
        100% {
            top: 135px;
        }
    }

    @keyframes wheel-close {
        0% {
            top: 135px;
        }
        100% {
            top: 20px;
        }
    }

    .form-open .robot-antenna {
        animation: antenna-open var(--robot-duration) forwards;
        animation-delay: var(--duration);
    }

    .chat-closing .robot-antenna {
        animation: antenna-close var(--robot-duration) forwards;
    }

    @keyframes antenna-open {
        0% {
            top: 25px;
        }
        100% {
            top: -20px;
        }
    }

    @keyframes antenna-close {
        0% {
            top: -20px;
        }
        100% {
            top: 25px;
        }
    }

    .form-open .robot-hand.left {
        animation: left-hand-show var(--robot-duration) forwards;
    }

    .form-open .robot-hand.right {
        animation: right-hand-show var(--robot-duration) forwards;
    }

    .chat-closing .robot-hand.left {
        animation: left-hand-hide var(--robot-duration) forwards;
    }
    
    .chat-closing .robot-hand.right {
        animation: right-hand-hide var(--robot-duration) forwards;
    }

    .form-open .robot-hand.left,
    .form-open .robot-hand.right {
        animation-delay: var(--duration);
    }

    @keyframes left-hand-show {
        0% {
            left: 10px;
        }
        100% {
            left: -14px;
        }
    }

    @keyframes left-hand-hide {
        0% {
            left: -14px;
        }
        100% {
            left: 10px;
        }
    }

    @keyframes right-hand-show {
        0% {
            right: 10px;
        }
        100% {
            right: -14px;
        }
    }

    @keyframes right-hand-hide {
        0% {
            right: -14px;
        }
        100% {
            right: 10px;
        }
    }

</style>
