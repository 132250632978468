var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$isSmallScreen)?_c('div',{staticClass:"home portrait mobile scroll-check"},[_c('NJT_Title'),(_vm.pageType === 'bus')?_c('BusCascadingSelections',{on:{"navigate-to-trips":this.navigateToTrips}}):_vm._e(),(_vm.pageType === 'bus')?_c('div',{staticClass:"divider"}):_vm._e(),(_vm.pageType !== 'lightrail')?_c('StopIDSelection',{on:{"navigate-to-trips":this.navigateToTrips}}):_c('LightRailCascadingSelections',{on:{"navigate-to-trips":this.navigateToTrips}}),(_vm.pageType === 'rail')?_c('div',{staticClass:"sts-nav-button",on:{"click":_vm.navigateTo_STS}},[_c('v-btn',{attrs:{"rounded":""}},[_vm._v(" Station To Station ")])],1):_vm._e(),_c('div',{staticClass:"divider"}),_c('NearbyContainer',{attrs:{"usingGeolocation":_vm.usingGeolocation,"currentPosition":_vm.currentPosition,"showMapButton":true,"getOwnData":true},on:{"navigate-to-stops":_vm.navigateToStops,"navigate-to-trips":_vm.navigateToTrips}}),(_vm.pageType !== 'lightrail')?_c('div',{staticClass:"map-button-container"},[_c('MapButton',{attrs:{"withLabel":true,"usingGeolocation":_vm.usingGeolocation,"query":_vm.query}})],1):_vm._e()],1):_c('div',{class:'home landscape ' + _vm.pageType},[_c('LaptopNavBar',{attrs:{"condensed":false,"pageType":_vm.pageType,"query":_vm.query}}),_c('div',{staticClass:"home-container"},[_c('div',{staticClass:"selections"},[_c('div',{style:({
                    display:'flex', 
                    flexDirection:'column', 
                    //height:this.topLandscapeHeight, 
                    alignItems:'center',
                    //justifyContent: 'space-between',
                })},[_c('StopIDSelection',{attrs:{"showRoute":_vm.pageType === 'bus'},on:{"navigate-to-trips":this.navigateToTrips}})],1)]),_c('div',{staticClass:"nearby"},[_c('NearbyContainer',{attrs:{"usingGeolocation":_vm.usingGeolocation,"currentPosition":_vm.currentPosition,"getOwnData":true},on:{"navigate-to-stops":_vm.navigateToStops,"navigate-to-trips":_vm.navigateToTrips}})],1),(_vm.pageType !== 'lightrail')?_c('div',{staticClass:"map-button-container"},[(_vm.pageType === 'rail')?_c('div',{staticClass:"sts-nav-button",on:{"click":_vm.navigateTo_STS}},[_c('v-btn',{attrs:{"rounded":""}},[_vm._v(" Station To Station ")])],1):_vm._e(),_c('MapButton',{attrs:{"withLabel":true,"usingGeolocation":_vm.usingGeolocation,"query":_vm.query}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }