
import axios from "axios";

const Base = "https://njconnect.dev.njtransit.com";

export function connectionCheck() {
    return axios.get(`${Base}/checkRun`);
}

export function runQuery(query, client_id) {
    console.log("CHAT REQUEST", query);

    //"http://passcomm-chatbot-dev-alb-1885555723.us-east-1.elb.amazonaws.com/njconnect"
    const url = `${Base}/njconnect`;

    return axios.post(
        url,
        { query, client_id }
    );
}
