
Container element for laying out the nearby routes and stops, 
as well as the MapButton (if it's set to be shown based on props)

<template>
    <div v-if="usingGeolocation" :class="'wrapper nearby-container' + ($isSmallScreen ? ' mobile' : ' landscape')  + ((isHorizontal && !$isSmallScreen) ? ' horizontal' : '')" >
        <div class="nearby-inner">
            <div v-if="!isHorizontal" style="width: 100%; display: flex; gap: 2rem; align-items: center; justify-content: center;">
                <div class="nearby-message">Close to you right now</div>
            </div>
            <NearbyRoutes :isHorizontal="isHorizontal" :getOwnData="getOwnData" :currentPosition="currentPosition" @navigate-to-stops="navigateToStops" />
            <NearbyStops :isHorizontal="isHorizontal" :getOwnData="getOwnData" :currentPosition="currentPosition" @navigate-to-trips="navigateToTrips" />
        </div>
    </div>
    <div v-else class="wrapper geo-disabled">
        Please enable geolocation to see nearby buses and routes
    </div>
</template>

<script>
    import NearbyRoutes from "@/data_display/NearbyRoutes";
    import NearbyStops from "@/data_display/NearbyStops";
    // import MapButton from "@/components/MapButton";

    export default {
        name: "NearbyContainer",
        props: ["usingGeolocation", "currentPosition", "showMapButton", "isHorizontal", "getOwnData"],
        components: {
            NearbyRoutes,
            NearbyStops,
            // MapButton
        },
        watch: { 
            isHorizontal: function(newVal, oldVal) {
                console.log('HORIZONTAL changed: ', newVal, ' | was: ', oldVal)
            }
        },
        methods: {
            navigateToStops(trip){
                this.$emit("navigate-to-stops", trip);
            },
            navigateToTrips(stop, route, direction, stopName, mode){
                this.$emit("navigate-to-trips", stop, route, direction, stopName, mode);
            } 
        }
    }
</script>

<style>
    .wrapper {
        /* margin-top: 12vh; what was this about? */
        padding-right: 6vw;
        text-align: center;
        font-size: 1.1rem;
    }

    .wrapper.geo-disabled {
        padding-top: calc(30 * var(--mvh));
    }

    .mobile .wrapper{
        margin-top: 0;
        margin-bottom: calc(1 * var(--mvh));
        padding-right: 0;
    }

    .mobile .map-container .wrapper {
        margin-top: calc(4 * var(--mvh));
    }

    .mobile .wrapper.geo-disabled {
        padding-top: initial;
    }

    .horizontal.wrapper {
        height: calc(25 * var(--mvh));
    }

    .horizontal .nearby-container {
        height: 100%;
    }

    .landscape.nearby-container {
        position: absolute;
    } 

    .map-container .landscape.nearby-container {
        top: calc(74 * var(--mvh));
    }

    .nearby-inner {
        /* margin: 0vh 2vw 4vh 0; */
        height: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        margin-top: calc(12 * var(--mvh));
    }

    .lightrail .nearby-inner {
        margin-top: calc(22 * var(--mvh));
    }

    .horizontal .nearby-inner {
        margin-top: 0;
        justify-content: center;
    }

    .mobile .nearby-inner {
        margin-top: 0;
    }

    .nearby-message {
        margin-bottom: calc(4 * var(--mvh));
        /* margin-right: 3vw; */
        color: var(--primary-color);
        letter-spacing: 0.1vw;
        position: relative;
        align-self: center;
        font-size: 2rem;
    }

    .mobile .nearby-message {
        font-size: 1.7rem;
    }

    /* .nearby-message::before {
        --line-height: 0.5vh;
        content: "";
        position: absolute;
        top: 50%;
        left: -7vw;
        height: var(--line-height);
        width: 5vw;
        background-color: #1867c0;
    } */

    .icon-test {
        margin-bottom: calc(4 * var(--mvh));
        /* margin-left: 1vw; */
        background: url("../assets/bus_icon_blue.svg");
        background-color: rgba(0,0,0,0.2);
        height: calc(6 * var(--mvh));
        width: calc(6 * var(--mvh));
        position: relative;
    }

    /* @media only screen and (max-width: 1050px) {
        .nearby {
            width: 90%;
            margin: 0 0 4vh 0;
        }
    }

    .nearby.mobile {
        align-items: center;
    } */

    /* @media only screen and (min-width: 601px) and (max-width: 1050px) {
        .nearby {
            align-items: center;
        }
    } */
</style>