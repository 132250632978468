
/*
	API calls for getting data to fill in maps
	- locations of vehicles within a certain radius
	- locations of stops and terminals within a certain radius
*/

import axios from "axios";
import { BaseURL, createFormData, getCurrentStore } from "./BaseRequestValues";

import RailRequests from "./RailRequests";

import axiosRetry from "axios-retry";

const FAILURE_LIMIT = 120; // 60;

let consecutiveFailureCount = 0;
let initialFailureTime = null;

//
//
let reqToken = "0123456789";

function viewReqToken(){
    return reqToken;
}

function setReqToken(v){
    reqToken = v;
}

// retry the requests is they initially fail
axiosRetry(axios, {
	retries: 0, // number of retries
	retryCondition: (error) => {
        console.log("AN ERROR", error);
        console.log("GET CURRENT VUE?", getCurrentStore(), error);

        if(error.response){
            console.log("AN ERROR DATA", error.response.data);
        }

        consecutiveFailureCount++;
        if(!initialFailureTime){
            initialFailureTime = Date.now();
        }

        console.log("CONSECUTIVE FAILURES RAIL", getCurrentStore(), consecutiveFailureCount);

        // if(consecutiveFailureCount >= FAILURE_LIMIT){
        if(failureDiff()){
            // call to the store to set disconnected mode
            // how do I call to the store from here?
            // do I need to pass something in to use as a callback?
            console.log("EXCEEDED CONSECUTIVE FAILURE LIMIT");

            // JUST SKIP THIS FOR NOW

            // TODO get this to work properly

            // getCurrentStore().commit("setIsDisconnected", true);

        }
		// if retry condition is not specified, by default idempotent requests are retried
		// return error.response.status === 503;

        /*
{
  "errorMessage": "Invalid token."
}
        */

        // make the retry probabilistic so it's sure to stop eventually no matter what
        const r = Math.random();
		return (r > 0.2) && false;
	}
});

function failureDiff() {
    const now = Date.now();

    console.log("FAILURE DIFF", now, initialFailureTime, now - initialFailureTime);

    if(!initialFailureTime){
        return false;
    }

    const sec = Math.abs(now - initialFailureTime)/1000;
    return sec >= FAILURE_LIMIT;
}

//
// check if the current token is valid
//
function checkToken(){
	const promise = new Promise((resolve) => {
		setReqToken("0123456789");
		resolve();
	});

	return promise;
}

/*
//
//
function isValidToken(){
    // const url = `${BaseURL}/TrainData/isValidToken/${token}`;
    // return axios.get(url);

    const url = `${BaseURL}/TrainData/isValidToken`;

    const data = createFormData({token});

    const config = {
        method: "post",
        url,
        data,
        timeout: 2000
    }

    return axios(config);
}
*/

//
// get new token if one doesn't yet exist
//
function getToken() {
	const promise = new Promise((resolve) => {
		setReqToken("0123456789");
		resolve();
	});

	return promise;
}

//
//
function resetErrors() {
    consecutiveFailureCount = 0;
    initialFailureTime = null;
    getCurrentStore().commit("setIsDisconnected", false);
}

//
//
function getRetryTimeout(rt){
    if(rt === 0){
        return 2000;
    }
    else if(rt === 1 || rt === -1){
        return 4000;
    }
    else if(rt === 2) {
        return 10000;
    }
    else if(rt > 2 && rt < 10) {
        return 20000;
    }
}

//
//
function runRequest(req, reAttemptCount = 0){
    return checkToken()
    .then(() => {
        const rt = viewReqToken();
        console.log("RETRY COUNT", reAttemptCount, req, getToken && getRetryTimeout);
        /*
        if(!rt || rt.length < 10){
            // something very weird has happened here...
            setReqToken(null);
            if(reAttemptCount >= 0 && reAttemptCount <= 4) {
                window.setTimeout(() => {
                    getToken()
                    .then(() => {
                        runRequest(req, reAttemptCount + 1);
                    });
                }, getRetryTimeout(reAttemptCount));
            }

            return;
        }
        */

        console.log("FINISHED CHECKING TOKEN");
        // reset the failure count
        return req(rt);
    })
    .catch(err => {
        console.log("CAUGHT EXCEPTION", err);

        /*
        let result = "";
        if(err.response){
            result = err.response.data;
            console.log("CAUGHT EXCEPTION RESULT", result);
        }

        if(result && result.errorMessage === "Invalid token."){
            // clear it so there's no confusion
            console.log("WE HAVE AN INVALID TOKEN", reAttemptCount);

            setReqToken(null);
            if(reAttemptCount >= 0 && reAttemptCount <= 10) {
                window.setTimeout(() => {
                    console.log("INVALID TOKEN RETRY!!!");
                    getToken()
                    .then(() => {
                        runRequest(req, reAttemptCount + 1);
                    });
                }, getRetryTimeout(reAttemptCount));
            }
            else if(reAttemptCount === -1){
                window.setTimeout(() => {
                    console.log("INVALID TOKEN RETRY!!!");
                    getToken()
                    .then(() => {
                        runRequest(req, -2);
                    });
                }, getRetryTimeout(reAttemptCount));
            }
        }
        else if(reAttemptCount >= 0 && reAttemptCount <= 10) {
            window.setTimeout(() => {
                console.log("OTHER ERROR RETRY!!!");
                runRequest(req, reAttemptCount + 1);
            }, getRetryTimeout(reAttemptCount));
        }
        else if(reAttemptCount === -1){
            window.setTimeout(() => {
                console.log("OTHER ERROR RETRY!!!");
                runRequest(req, -2);
            }, getRetryTimeout(reAttemptCount));
        }
        */
    });
}

//
//
export function getVehicleLocations(params) {

	const req = () => {

		console.log("GET VEHICLE LOCATIONS PPP", params);
	
		if(params.mode === "RAIL") {
			return RailRequests.getRailVehicleLocations(params);
		}
	
		const data = createFormData({
			lat: params.lat,
			lon: params.lng,
			radius: params.radius,
			mode: params.mode ? params.mode : "ALL"
		});
	
		const url = `${BaseURL}/api/busDV/getVehicleLocations`;
	
		var config = {
			method: 'post',
			url,
			data,
			timeout: 5000
		};
	
		return axios(config)
		.then(({data}) => {
			resetErrors();
			return {data};
		});
	}

	return runRequest(req);
}

let reqCount = 0;


//
//
export function getIndividualVehicleLocation(params) {

	const req = () => {

		console.log("GET INDIVIDUAL VEHICLE LOCATION", params);
	
		if(params.mode === "RAIL") {
			return RailRequests.getIndividualTrainLocation(params);
		}

        return {};

        /*

		const data = createFormData({
			lat: params.lat,
			lon: params.lng,
			radius: params.radius,
			mode: params.mode ? params.mode : "ALL"
		});
	
		const url = `${BaseURL}/api/busDV/getVehicleLocations`;
	
		var config = {
			method: 'post',
			url,
			data,
			timeout: 5000
		};
	
		return axios(config)
		.then(({data}) => {
			resetErrors();
			return {data};
		});

        */
	}

	return runRequest(req);
}


//
//
export function getStopAndTerminalLocations(params) {

	const req = () => {

		console.log("GET STOP LOCATIONS PPP", params);
	
		reqCount++;
	
		if(params.mode === "RAIL") {
			console.log("GET RAIL STOP LOCATIONS");
			return RailRequests.getRailStopLocations(params);
		}
	
		const data = createFormData({
			lat: params.lat,
			lon: params.lng,
			radius: params.radius,
			direction: null,
			route: null,
			mode: params.mode ? params.mode : "ALL",
            timeout: 5000
		});
	
		const url = `${BaseURL}/api/busDV/getBusLocationsData`;
	
		var config = {
			method: 'post',
			url,
			data,
			timeout: 7000
		};

		console.log("STOPS TIMEOUT REQUEST?", reqCount, new Date());

		return axios(config)
		.then(({data}) => {
			resetErrors();
			return {data};
		});
	}

	return runRequest(req);
}

//
//
export function getStopName(code){
    const req = () => {
        const url = `${BaseURL}/api/busDV/getStopName`;

        const data = createFormData({stopnum: code});

        const config = {
            method: 'post',
			url,
			data,
			timeout: 2000
        }

        return axios(config)
		.then(({data}) => {
			resetErrors();
			return {data};
		});
    }

    return runRequest(req);
}